

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'

import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Isvg from 'react-inlinesvg';
import trashIcon from './assets/svg/orders-trash.svg';

import DocumentMeta from 'react-document-meta';
import { withRouter } from 'react-router-dom';
import {baseUrl} from "./helpers/constants";
import 'react-quill/dist/quill.snow.css';
const rootReducer = combineReducers({
  form: formReducer
});

const store = createStore(rootReducer)


Number.prototype.formatPrice = function (sep = 2) {
  let dec_point = '.';
  let thousands_sep = ',';

  var parts = parseFloat(this).toFixed(sep).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point);
}

String.prototype.formatPrice = function (sep = 2) {
  let dec_point = '.';
  let thousands_sep = ',';

  var parts = parseFloat(this).toFixed(sep).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point);
}


class App extends Component {


  constructor(props) {
    super(props);
    this.addToCart = this.addToCart.bind(this);
    this.verifyUser = this.verifyUser.bind(this);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.showInfoMessage = this.showInfoMessage.bind(this);
    this.hideInfoMessage = this.hideInfoMessage.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateCart = this.updateCart.bind(this);
    this.updateMeta = this.updateMeta.bind(this);



    if (typeof window !== "undefined") {
      window.googleMapsCallback = this.googleMapsCallback;
    }

    this.state = {
      pages: [],
      uData: null,
      productAddedToCart: null,
      cartItems: 0,
      infoMessages: {

      },

    };
  }

  handleDelete(func) {
    this.setState({
      deletePrompt: func
    });
  }
  handlePrompt = (data) => {
    this.setState({
      customPrompt: data
    });
  }



  showInfoMessage(text, error) {
    console.log(this.state.infoMessages);
    let messages = this.state.infoMessages;
    let idx = Date.now().toString();
    messages[idx] = {
      idx: idx,
      message: text,
      error: error
    };

    this.setState({
      infoMessages: messages
    }, () => {
      this.forceUpdate();
      setTimeout(() => {
        this.hideInfoMessage(idx);
      }, 3000);
    });



  }

  hideInfoMessage(idx) {
    let messages = this.state.infoMessages;
    if (!messages[idx])
      return;
    messages[idx].animate = true;
    this.setState({
      infoMessages: messages
    }, () => {
      setTimeout(() => {


        let messages = this.state.infoMessages;
        delete messages[idx];
        this.setState({
          infoMessages: messages
        })
      }, 1000);
    })
  }


  googleMapsCallback() {
    this.setState({
      _googleMapsLoaded: true
    })
  }

  componentDidMount() {
    this.verifyUser();
    this.fetchCartInfo();

    fetch(`${baseUrl}/blog/latest`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      },
    }).then(res => res.json()).then((result) => {
      this.setState({
        latestBlog: result
      })
    })

    this.props.history.listen((location, action) => {
      // ReactGA.pageview(location.pathname + location.search);
       if (window && window.gtag){
         window.gtag('js', new Date());

         window.gtag('config', 'UA-196543804-1');

       }

       if (window && window.fbq){
          window.fbq('init', '885165805754074');
          window.fbq('track', 'PageView');
       }

     });

  }

  verifyUser() {
    fetch(`${baseUrl}/user/verify`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    }).then(res => {
      return res.json()
    }).then((result) => {
      if (!result.error) {
        this.fetchCartInfo();

        this.setState({
          uData: result
        })
      }
    })

  }

  addToCart(product) {
    if (this.state.uData) {
      fetch(`${baseUrl}/cart/add/${product._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then(res => res.json()).then((result) => {
        if (!result.error) {
          this.showInfoMessage('Artikal je uspješno dodat u korpu');
          this.fetchCartInfo();

        }
      })

    } else {
      let cart = localStorage.getItem('cart');
      if (!cart) {
        cart = [];
      } else {
        cart = JSON.parse(cart);
      }

      let found = false;
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].productId == product._id) {
          cart[i].quantity++;
          found = true;
        }

      }

      if (!found) {
        cart.push({
          productId: product._id,
          quantity: 1
        })
      }
      this.showInfoMessage('Artikal je uspješno dodat u korpu');

      localStorage.setItem('cart', JSON.stringify(cart));
      this.fetchCartInfo();

    }
  }

  updateCart(product, quantity, callback) {
    if (this.state.uData) {
      fetch(`${baseUrl}/cart/update/${product._id}/${quantity}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then(res => res.json()).then((result) => {
        if (!result.error) {
          this.fetchCartInfo();
          callback();
        }
      })

    } else {
      let cart = localStorage.getItem('cart');
      if (!cart) {
        cart = [];
      } else {
        cart = JSON.parse(cart);
      }

      let found = false;
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].productId == product._id) {
          cart[i].quantity = quantity;
          found = true;
          localStorage.setItem('cart', JSON.stringify(cart));
          this.fetchCartInfo();
          callback();
        }

      }

    }
  }

  updateMeta(data) {
    this.setState({
      metaTags: data
    })
  }

  fetchCartInfo = () => {
    let authToken, cart;
    if (typeof localStorage !== 'undefined') {
        authToken = 'Bearer ' + localStorage.getItem('authToken');
        cart = localStorage.getItem('cart');
        if (!cart) {
            cart = [];
        } else {
            cart = JSON.parse(cart);
        }
    }

    fetch(`${baseUrl}/cart`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': authToken
        },
        body: JSON.stringify({ cart })
    }).then(res => res.json()).then((result) => {

        this.setState({
          cartItems: result.length
        })

    })

  }

  render() {
    let meta;

    if (this.state.metaTags) {
      meta = {
        title: this.state.metaTags.title + ' - eeshop.ba | Kupuj pouzdano, Dostavljamo brzo!',
        description: this.state.metaTags.description ? this.state.metaTags.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            'og:title': this.state.metaTags.title + ' - eeshop.ba | Kupuj pouzdano, Dostavljamo brzo!',
            'og:image': this.state.metaTags['og:image'] ? this.state.metaTags['og:image'] : null,
            'og:description': this.state.metaTags.description ? this.state.metaTags.description : null
          }
        }
      };


    }

    return (
      <Provider store={store}>
        {
          meta ?

            <DocumentMeta {...meta}>
            </DocumentMeta>

            :
            null
        }
        <Routes
          {...this.state}
          {...this.props}
          signOut={() => { localStorage.removeItem('authToken'); this.setState({ uData: null }) }}
          hideAddToCartModal={() => this.setState({ productAddedToCart: null })}
          addToCart={this.addToCart}
          verifyUser={this.verifyUser}
          handleDelete={this.handleDelete}
          handlePrompt={this.handlePrompt}
        fetchCartInfo={this.fetchCartInfo}
          updateCart={this.updateCart}
          updateMeta={this.updateMeta}
        />


        <div className="pop-up-messages">
          {
            Object.values(this.state.infoMessages).map((item, idx) => {
              console.log(item);
              return (
                <div className={item.animate ? 'hide-message ' : ''} key={idx} onClick={() => this.hideInfoMessage(item.idx)}>
                  <i className="mdi mdi-close hide" />

                  <p className={item.error ? 'error' : ''}>{item.error ? <i className="mdi mdi-close" ></i> : null}{item.message}</p>
                </div>
              )
            })
          }

        </div>

        {this.state.deletePrompt ?
          <div className="delete-modal">
            <div>
              <Isvg src={trashIcon} />
              <h6>Potvrdite brisanje</h6>
              <div className="buttons">
                <button onClick={() => this.setState({ deletePrompt: null })}>NE</button>
                <button onClick={() => {
                  this.state.deletePrompt();
                  this.setState({
                    deletePrompt: null
                  })
                }
                }>DA</button>
              </div>
            </div>

          </div>
          :
          null
        }

        {this.state.customPrompt ?
          <div className="delete-modal">
            <div>
              <h6>{this.state.customPrompt.text}</h6>
              <div className="buttons">
                <button onClick={() => this.setState({ customPrompt: null })}>NE</button>
                <button onClick={() => {
                  this.state.customPrompt.callback();
                  this.setState({
                    customPrompt: null
                  })
                }
                }>DA</button>
              </div>
            </div>

          </div>
          :
          null
        }


      </Provider>

    );

  }

}

export default withRouter(App);
