export default [
    {
        "name": "Sarajevo",
        "cities": [
            "Hadžići",
            "Ilidža",
            "Ilijaš",
            "Sarajevo - Centar",
            "Sarajevo-Novi Grad",
            "Sarajevo-Novo Sarajevo",
            "Sarajevo-Stari Grad",
            "Trnovo",
            "Vogošća"
        ]
    },
    {
        "name": "Tuzlanski",
        "cities": [
            "Banovići",
            "Doboj-Istok",
            "Gradačac",
            "Gračanica",
            "Kalesija",
            "Kladanj",
            "Lukavac",
            "Sapna",
            "Srebrenik",
            "Teočak",
            "Tuzla",
            "Čelić",
            "Živinice"
        ]
    },
    {
        "name": "Zeničko-Dobojski",
        "cities": [
            "Breza",
            "Doboj-Jug",
            "Kakanj",
            "Maglaj",
            "Olovo",
            "Tešanj",
            "Usora",
            "Vareš",
            "Visoko",
            "Zavidovići",
            "Zenica",
            "Žepče"
        ]
    },
    {
        "name": "Unsko-Sanski",
        "cities": [
            "Bihać",
            "Bosanska Krupa",
            "Bosanski Petrovac",
            "Bužim",
            "Cazin",
            "Ključ",
            "Sanski Most",
            "Velika Kladuša"
        ]
    },
    {
        "name": "Posavski",
        "cities": [
            "Domaljevac",
            "Odžak",
            "Orašje",
            "Šamac"
        ]
    },
    {
        "name": "Bosansko-podrinjski",
        "cities": [
            "Foča",
            "Goražde",
            "Pale"
        ]
    },
    {
        "name": "Srednjobosanski",
        "cities": [
            "Bugojno",
            "Busovača",
            "Dobretići",
            "Donji Vakuf",
            "Fojnica",
            "Gornji Vakuf - Uskoplje",
            "Jajce",
            "Kiseljak",
            "Kreševo",
            "Novi Travnik",
            "Travnik",
            "Vitez"
        ]
    },
    {
        "name": "Hercegovačko-Neretvanski",
        "cities": [
            "Grad Mostar",
            "Jablanica",
            "Konjic",
            "Neum",
            "Prozor",
            "Ravno",
            "Stolac",
            "Čapljina",
            "Čitluk"
        ]
    },
    {
        "name": "Zapadno-Hercegovački",
        "cities": [
            "Grude",
            "Ljubuški",
            "Posušje",
            "Široki Brijeg"
        ]
    },
    {
        "name": "Livanjski",
        "cities": [
            "Bosansko Grahovo",
            "Drvar",
            "Glamoč",
            "Kupres",
            "Livno",
            "Tomislavgrad"
        ]
    },
    {
        "name": "Banjalučka",
        "cities": [
            "Banja Luka",
            "Gradiška",
            "Istočni Drvar",
            "Jezero",
            "Kneževo",
            "Kostajnica",
            "Kotor Varoš",
            "Kozarska Dubica",
            "Krupa na uni",
            "Kupres ",
            "Laktaši",
            "Mrkonjić Grad",
            "Novi Grad",
            "Oštra Luka",
            "Petrovac",
            "Prijedor",
            "Prnjavor",
            "Ribnik",
            "Srbac",
            "Čelinac",
            "Šipovo"
        ]
    },
    {
        "name": "Dobojsko-Bijeljinska",
        "cities": [
            "Bijeljina",
            "Bosanski Brod",
            "Derventa",
            "Doboj",
            "Donji Žabar",
            "Lopare",
            "Lukavac",
            "Modriča",
            "Pelagićevo",
            "Petrovo",
            "Stanari",
            "Teslić",
            "Tešanj",
            "Travnik",
            "Tuzla",
            "Ugljevik",
            "Vukosavlje",
            "Šamac"
        ]
    },
    {
        "name": "Sarajevsko-Zvornička",
        "cities": [
            "Bratunac",
            "Han Pijesak",
            "Ilijaš",
            "Istočni Stari Grad",
            "Kasindo",
            "Kladanj",
            "Lukavica",
            "Milići",
            "Olovo",
            "Osmaci",
            "Pale",
            "Rogatica",
            "Rudo",
            "Sarajevo-Novi Grad",
            "Sokolac",
            "Srebrenica",
            "Trnovo",
            "Ustiprača",
            "Višegrad",
            "Vlasenica",
            "Zvornik",
            "Šekovići",
            "Žepa"
        ]
    },
    {
        "name": "Trebinjsko-Fočanska",
        "cities": [
            "Berkovići",
            "Bileća",
            "Foča",
            "Gacko",
            "Istočni Mostar",
            "Kalinovik",
            "Ljubinje",
            "Nevesinje",
            "Trebinje",
            "Čajniče"
        ]
    },
    {
        "name": "Distrikt Brčko",
        "cities": [
            "Brčko"
        ]
    }
]