import React, { Component } from 'react';

export class EditorComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Quill: null,
            BlotFormatter: null
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            Promise.all([import('react-quill'), import('@botom/quill-resize-module')]).then(([QuillImport, ResizeModuleImport]) => {
                const Quill = QuillImport.default
                const ResizeModule = ResizeModuleImport.default
                Quill.Quill.register("modules/resize", ResizeModule);
                this.setState({ Quill, ResizeModule });
            })
        } else {
            this.setState({ Quill: null });
        }
    }

    render() {
        const ReactQuill = this.state.Quill;
        const toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],
            [{ 'align': [] }],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        ];
        return (
            <div>
                {ReactQuill ? (
                    <ReactQuill
                        modules={{
                            toolbar: toolbarOptions,
                            resize: {
                                toolbar: {
                                    showSize: true,
                                    alingTools: false,
                                },
                                locale: {},
                            },
                        }}
                        className="rich-editor"
                        style={this.props.editorStyle}
                        theme="snow"
                        onChange={this.props.onChange || function () {}}
                        value={this.props.value || ''}
                    />
                ) : (
                    "Loading..."
                )}
            </div>
        );
    }
}

export default EditorComponent;
