import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';


import {
    Container,
    Row,
    Col,

} from 'reactstrap';
import GoogleLogin from 'react-google-login';
import GitHubLogin from '../../components/githubLogin';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import Form from '../../components/forms/loginForm';

import downArrow from '../../assets/svg/down-arrow.svg';
import facebook from '../../assets/svg/login-facebook.svg';
import twitter from '../../assets/svg/login-twitter.svg';
import google from '../../assets/svg/login-google.svg';
import github from '../../assets/svg/github.svg';


import bg from '../../assets/images/bg.jpg';
import {baseUrl} from "../../helpers/constants";


class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.githubLogin = this.githubLogin.bind(this);
        this.facebookLogin = this.facebookLogin.bind(this);
        this.googleLogin = this.googleLogin.bind(this);

        this.state = {

        };



    }




    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.props.updateMeta(this.props.generateSeoTags(null));


    }

    githubLogin(code) {
        let cart = localStorage.getItem('cart');
        if (!cart) {
            cart = [];
        } else {
            cart = JSON.parse(cart);
        }

        fetch(`${baseUrl}/user/login/github`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code: code, cart: cart })
        }).then(res => res.json()).then((result) => {
            if (result.token) {
                localStorage.setItem('authToken', result.token);
                localStorage.removeItem('cart');
                this.props.verifyUser();
                this.props[0].history.push('/')
            }
        })

    }

    googleLogin(data) {
        console.log(data);
        if (!data.accessToken) {
            return;
        }

        let cart = localStorage.getItem('cart');
        if (!cart) {
            cart = [];
        } else {
            cart = JSON.parse(cart);
        }

        fetch(`${baseUrl}/user/login/google`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ accessToken: data.accessToken, cart: cart })
        }).then(res => res.json()).then((result) => {
            if (result.token) {
                localStorage.setItem('authToken', result.token);
                localStorage.removeItem('cart');
                this.props.verifyUser();
                this.props[0].history.push('/')
            }
        })

    }

    facebookLogin(data) {
        console.log(data);
        if (!data.accessToken) {
            return;
        }

        let cart = localStorage.getItem('cart');
        if (!cart) {
            cart = [];
        } else {
            cart = JSON.parse(cart);
        }

        fetch(`${baseUrl}/user/login/facebook`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ accessToken: data.accessToken, cart: cart })
        }).then(res => res.json()).then((result) => {
            if (result.token) {
                localStorage.setItem('authToken', result.token);
                localStorage.removeItem('cart');
                this.props.verifyUser();
                this.props[0].history.push('/')
            }
        })

    }

    submit(data) {
        let cart = localStorage.getItem('cart');
        if (!cart) {
            cart = [];
        } else {
            cart = JSON.parse(cart);
        }



        fetch(`${baseUrl}/user/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...data, cart })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                localStorage.setItem('authToken', result.token);
                localStorage.removeItem('cart');
                this.props.verifyUser();
                this.props[0].history.push('/')
            }
        })
    }



    render() {

        return (
            <div className="login-wrap">
                <div className="into-wrap">
                    <div className="background"><img src={bg} /></div>
                    <Container>
                        <Row>
                            <Col lg="8">
                                <h1>Dobrodošli nazad</h1>
                                <h2>Za brži pristup prijavite se<br />
                                    pomoću društvenih mreža</h2>
                                <button className="scroll-down">
                                    <Isvg src={downArrow} />
                                </button>
                                <div className="social-buttons">
                                    <FacebookLogin
                                        appId="141516643967911"
                                        callback={this.facebookLogin}
                                        render={renderProps => (
                                            <button className="facebook" onClick={renderProps.onClick}><Isvg src={facebook} /> {typeof window !== 'undefined' && window.innerWidth < 768 ? 'Facebook' : 'Login with Facebook'}</button>
                                        )}
                                    />

                                    <button className="twitter"><Isvg src={twitter} />{typeof window !== 'undefined' && window.innerWidth < 768 ? 'Twitter' : 'Login with Twitter'}</button>

                                    <GoogleLogin
                                        clientId="971274133955-83j9n1f0j1d6tlgr4a50lckf3kfoucep.apps.googleusercontent.com"
                                        render={renderProps => (
                                            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="google"><Isvg src={google} />Login with Google</button>
                                        )}
                                        buttonText="Login"
                                        onSuccess={this.googleLogin}
                                        onFailure={(data) => { console.log(data) }}
                                    />



                                </div>
                            </Col>
                            <Col lg={{ size: 4 }}>
                                <div className="form-box">
                                    <div className="form-wrap">
                                        <h6>Login</h6>
                                        <p>Prijavite se na svoj nalog da biste nastavili.</p>
                                        <Form onSubmit={this.submit} />
                                        {this.state.error ? <p className="error">{this.state.error}</p> : null}
                                    </div>
                                    <div className="spacer"></div>
                                    <p className="bottom-link">Niste registrovani? <Link to='/register'>Kreirajte nalog</Link></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <p className="copyright">© 2020 eeshop.ba All rights reserved. Made with love for a better web.</p>

                </div>



            </div>
        );
    }
}

export default Page(LoginPage);
