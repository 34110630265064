import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import BlogArticle from '../../components/articles/blogArticle';


import rightArrow from '../../assets/svg/right-arrow.svg';
import user from '../../assets/svg/user.svg';
import penIcon from '../../assets/svg/orders-pen.svg';
import trashIcon from '../../assets/svg/orders-trash.svg';
import {baseUrl} from "../../helpers/constants";


class CategoriesPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {
            ...props.initialData,
            categories: []
        };
    }

    componentDidMount() {

        window.scrollTo(0, 0);


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }

        fetch(`${baseUrl}/categories/tree`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        }).then(res => res.json()).then((result) => {
            this.setState({
                categories: result
            })
        })

    }

    submit(data) {
        console.log(data);
        fetch(`${baseUrl}/stores/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                this.setState({
                    done: true
                })
            }
        })

    }

    _renderTree = (items) => {
        console.log(items);
        return items.map((item, idx) => {
            return (
                <li key={idx}>
                    <div className="head"><span>{item.name}</span>
                        <div> <Link to={`/account/categories/${item._id}`}> <button><Isvg src={penIcon} /> </button> </Link>

                            <button onClick={() => this.props.handleDelete(() => {
                                fetch(`${baseUrl}/categories/delete/${item._id}`, {
                                    method: 'DELETE',
                                    headers: {
                                        'content-type': 'application/json',
                                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`

                                    },
                                }).then(res => res.json()).then((result) => {

                                    fetch(`${baseUrl}/categories/tree`, {
                                        method: 'GET',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                                        },
                                    }).then(res => res.json()).then((result) => {
                                        this.setState({
                                            categories: result
                                        })
                                    })

                                })

                            })}><Isvg src={trashIcon} /></button>

                        </div>
                    </div>

                    {
                        item.subCategories && item.subCategories.length ?
                            <ul>
                                {this._renderTree(item.subCategories)}
                            </ul>
                            :

                            null
                    }


                </li>
            )
        }
        )
    }

    render() {

        return (
            <div className="account-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="4" className="user-info">
                                <Isvg src={user} />
                                <div>
                                    <h1>Moj nalog</h1>
                                    <p className="email">{this.props.uData && this.props.uData.email}</p>
                                    <button onClick={() => this.props.signOut()}>Izloguj se</button>
                                </div>
                            </Col>
                            <Col lg={{ size: 8 }} className="user-nav">
                                <Link to='/account/orders'><button >MOJE NARUDŽBE</button></Link>
                                <Link to='/account/edit'><button>PODEŠAVANJA</button></Link>
                                <Link to='/account/reviews'><button >OCJENE</button></Link>
                                {this.props.uData && this.props.uData.permissions && this.props.uData.permissions.indexOf('*') !== -1 ? <Link to='/account/categories'><button className="active">ADMINISTRACIJA</button></Link> : null}
                            </Col>
                        </Row>

                    </Container>
                </div>


                <section >
                    <Container>
                        <Row>
                            <Col lg="12" >
                                <div className="admin-form">
                                    <ul className="tabs">
                                        <li className="active">
                                            <Link to='/account/categories'>Kategorije</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/users'>Korisnici</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/stores'>Radnje</Link>
                                        </li>

                                        <li>
                                            <Link to='/account/news'>Novosti</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/pages'>Stranice</Link>
                                        </li>

                                        <li>
                                            <Link to='/account/statistics'>Statistike</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/create-store'>OTVORITE SHOP</Link>
                                        </li>
                                    </ul>

                                    <div className="form-wrapper">
                                        <div className="top">
                                            <h3>Kategorije</h3>
                                            <Link to='/account/categories/new'>
                                                <button className="button">Nova kategorija</button>

                                            </Link>

                                        </div>
                                        {this.state.categories.length ?
                                            <ul className="categories-tree">
                                                {this._renderTree(this.state.categories)}
                                            </ul>

                                            :
                                            null
                                        }


                                    </div>
                                </div>

                            </Col>
                        </Row>

                    </Container>

                </section>




            </div>
        );
    }
}

export default Page(CategoriesPage);
