import HomePage from './views/homePage';
import CategoryPage from './views/categoryPage';
import DetailPage from './views/detailPage';
import StoreProfilePage from './views/store/profile';
import StoreOrdersPage from './views/store/orders';
import StoreProductsPage from './views/store/products';
import StoreProductPage from './views/store/product';

import DownloadsPage from './views/account/downloadsPage';
import ReviewsPage from './views/account/reviewsPage';

import CartPage from './views/cart/cartPage';
import LoginPage from './views/account/loginPage';
import RegisterPage from './views/account/registerPage';
import ResetPasswordPage from './views/account/resetPasswordPage';
import BlogPage from './views/blog/blogPage';
import BlogDetailPage from './views/blog/blogDetailPage';
import EditAccountPage from './views/account/editAccountPage';
import EmailVerifyPage from './views/account/emailVerifyPage';
import ChangePasswordPage from './views/account/changePasswordPage';
import CheckoutPage from './views/cart/checkoutPage';
import DynamicPage from './views/dynamicPage';
import AccountStorePage from './views/account/storePage';
import AccountCategoryPage from './views/account/categoryPage';
import AccountCategoriesPage from './views/account/categoriesPage';
import StoreInvoicePage from './views/store/invoice';
import StoreSettingsPage from './views/store/settings';
import StoreContactsPage from './views/store/contacts';

import AccountNewsPage from './views/account/blogPage';
import AccountNewsItemPage from './views/account/blogItemPage';
import AccountUsersPage from './views/account/usersPage';
import AccountPagesPage from './views/account/pagesPage';
import AccountPageItemPage from './views/account/pageItemPage';

import StoresPage from './views/storesPage';
import StoreStatisticsPage from './views/store/statistics';
import StoreAdminsPage from './views/store/admins';
import ContactPage from './views/contactPage';
import editUserPage from './views/account/editUserPage';
import storesPage from './views/account/storesPage';
import editStorePage from './views/account/editStorePage';
import admin from './views/store/admin';
import AdminStatistics from './views/account/statistics';
import {baseUrl} from "./helpers/constants";

export const routes = [
    {
        path: "/",
        component: HomePage,
        exact: true,
        generateSeoTags: (data) => {
            return {
                title: 'Početna',
                description: 'Kupuj pouzdano. Dostavljamo brzo!',
                'og:url': 'https://www.eeshop.ba',
                'og:type': 'website',
                'og:description': 'Kupuj pouzdano. Dostavljamo brzo!',
                'og:image': 'https://eeshop.ba/share.png'

            }
        },
        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/categories`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        categories: result,
                    }
                })

            },

            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/products/promoted`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        promotedProducts: result,
                    }
                })
            },
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/products/latest`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        latestProducts: result,
                    }
                })
            },

            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/promoted`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        promotedStores: result,
                    }
                })
            },


        ]
    },
    {
        path: "/shopovi",
        component: StoresPage,
        exact: true,
        generateSeoTags: (data) => {
            return {
                title: 'Shop-ovi',
                description: 'Kupuj pouzdano. Dostavljamo brzo!',
                'og:url': 'https://www.eeshop.ba',
                'og:type': 'website',
                'og:description': 'Kupuj pouzdano. Dostavljamo brzo!'
            }
        },

        loadData: [

            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/stores`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page ? query.page : 0,
                        search: query.search ? query.search : null,
                        letter: query.letter ? query.letter : null

                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        items: result.items,
                        total: result.total,
                        totalCount: result.totalCount
                    }
                })

            },
        ]
    },
    {
        path: "/kategorije",
        component: CategoryPage,
        generateSeoTags: (data) => {
            return {
                title: data.category && data.category.name ? data.category.name : 'Kategorije',
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.category && data.category.products && data.category.products[0] && data.category.products[0].images && data.category.products[0].images[0]
            }
        },

        loadData: [
            (fetchFunction, match, pathname, query) => {
                console.log(query);

                if (query && query['compatible-with'] && typeof query['compatible-with'] == 'string') {
                    query['compatible-with'] = query['compatible-with'].split(',');
                }
                if (query && query['tags'] && typeof query['tags'] == 'string') {
                    query['tags'] = query['tags'].split(',');
                }
                if (query && query['page']) {
                    query['page'] = parseInt(query['page']);
                }else{
                    query['page'] = 0;
                }

                let breadcrumb = pathname.split('/kategorije').pop();

                return fetchFunction(`${baseUrl}/category`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ breadcrumb: breadcrumb, query: query })
                }).then(res => res.json()).then((result) => {

                    return {
                        category: result
                    }
                })

            },
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/categories/tree`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        categories: result,
                    }
                })

            },

        ]
    }, {
        path: "/artikal/:storeAlias/:alias/:sku",
        exact: true,
        component: DetailPage,
        generateSeoTags: (data) => {
            return {
                title: data.product && data.product.name,
                description: data.product && data.product.shortDescription,
                'og:image': data.product && data.product.images && data.product.images[0]
            }
        },

        loadData: [
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/products/get/${match.params.storeAlias}/${match.params.alias}/${match.params.sku}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    //console.log(result)
                    let obj = {
                        product: result,
                        variation: result && result.variations && result.variations[0] ? result.variations[0]._id   : null
                    }
                    return obj
                })

            },


        ]
    },

    {
        path: "/artikal/:storeAlias/:alias/:sku/:tab",
        exact: true,
        component: DetailPage,
        generateSeoTags: (data) => {
            return {
                title: data.product && data.product.name,
                description: data.product && data.product.shortDescription,
                'og:image': data.product && data.product.images && data.product.images[0]
            }
        },
        loadData: [
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/products/get/${match.params.storeAlias}/${match.params.alias}/${match.params.sku}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    //console.log(result)
                    let obj = {
                        product: result,
                        variation: result && result.variations && result.variations[0] ? result.variations[0]._id   : null
                    }
                    return obj
                })

            },

            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }

                return fetchFunction(`${baseUrl}/products/comments/${match.params.storeAlias}/${match.params.alias}/${match.params.sku}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        comments: result,
                    }
                })

            },
            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }
                return fetchFunction(`${baseUrl}/products/reviews/${match.params.storeAlias}/${match.params.alias}/${match.params.sku}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        reviews: result,
                    }
                })

            },
            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }
                return fetchFunction(`${baseUrl}/products/review/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        review: result,
                    }
                })

            },

            (fetchFunction, match, pathname, query) => {
                let authToken, cart;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }

                return fetchFunction(`${baseUrl}/products/isRatingAllowed/${match.params.storeAlias}/${match.params.alias}/${match.params.sku}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                }).then(res => res.json()).then((result) => {

                    return result;
                })
            },


        ]
    },

    {
        path: "/shop/:storeAlias",
        exact: true,
        component: StoreProfilePage,
        generateSeoTags: (data) => {
            return {
                title: data.storeData && data.storeData.name,
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },
        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/products/get/store/` + match.params.storeAlias + '/all', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page ? query.page : 0,
                        search: query.search ? query.search : null
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        products: result.items,
                        totalProducts: result.total
                    }
                })

            },


        ]
    },
    {
        path: "/shop/:storeAlias/izdvojeni",
        exact: true,
        component: StoreProfilePage,
        generateSeoTags: (data) => {
            return {
                title: 'Izdvojeni artikli - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/products/get/store/` + match.params.storeAlias + '/promoted', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page ? query.page : 0,
                        search: query.search ? query.search : null
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        products: result.items,
                        totalProducts: result.total
                    }
                })

            },


        ]
    }, {
        path: "/shop/:storeAlias/na-akciji",
        exact: true,
        component: StoreProfilePage,
        generateSeoTags: (data) => {
            return {
                title: 'Artikli na akciji - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/products/get/store/` + match.params.storeAlias + '/offer', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page ? query.page : 0,
                        search: query.search ? query.search : null
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        products: result.items,
                        totalProducts: result.total
                    }
                })

            },


        ]
    },
    {
        path: "/shop/:storeAlias/o-nama",
        exact: true,
        component: StoreProfilePage,
        generateSeoTags: (data) => {
            return {
                title: 'O nama - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },

        ]
    }, {
        path: "/shop/:storeAlias/mapa",
        exact: true,
        component: StoreProfilePage,
        generateSeoTags: (data) => {
            return {
                title: 'Mapa - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },

        ]
    },
    {
        path: "/shop/:storeAlias/kontakt",
        exact: true,
        component: StoreProfilePage,
        generateSeoTags: (data) => {
            return {
                title: 'Kontakt - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },

        ]
    },
    {
        path: "/shop/:storeAlias/radno-vrijeme",
        exact: true,
        component: StoreProfilePage,
        generateSeoTags: (data) => {
            return {
                title: 'Radno vrijeme - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },

        ]
    },



    {
        path: "/shop/:storeAlias/orders",
        exact: true,
        component: StoreOrdersPage,
        generateSeoTags: (data) => {
            return {
                title: 'Narudzbe - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },

            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }

                return fetchFunction(`${baseUrl}/orders/get/` + match.params.storeAlias, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({
                        page: query.page ? query.page : 0,
                        search: query.search ? query.search : null
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        orders: result.items,
                        totalOrders: result.total
                    }
                })

            },

        ]
    },
    {
        path: "/shop/:storeAlias/contacts",
        exact: true,
        component: StoreContactsPage,
        generateSeoTags: (data) => {
            return {
                title: 'Upiti - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },

            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }

                return fetchFunction(`${baseUrl}/contacts/get/store/` + match.params.storeAlias, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({
                        page: query.page ? query.page : 0,
                        search: query.search ? query.search : null
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        items: result.items,
                        total: result.total
                    }
                })

            },

        ]
    },

    {
        path: "/shop/:storeAlias/invoice/:id",
        exact: true,
        component: StoreInvoicePage,
        generateSeoTags: (data) => {
            return {
                title: 'Narudzbenica - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [

            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }

                return fetchFunction(`${baseUrl}/orders/get/` + match.params.storeAlias + '/' + match.params.id, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        order: result
                    }
                })

            },
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }
                })

            },



        ]
    },

    {
        path: "/shop/:storeAlias/products",
        exact: true,
        component: StoreProductsPage,
        generateSeoTags: (data) => {
            return {
                title: 'Artikli - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },

            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }

                return fetchFunction(`${baseUrl}/products/get/` + match.params.storeAlias, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({
                        page: query.page ? query.page : 0,
                        search: query.search ? query.search : null
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        products: result.items,
                        totalProducts: result.total
                    }
                })

            },



        ]
    },

    {
        path: "/shop/:storeAlias/statistics",
        exact: true,
        component: StoreStatisticsPage,
        generateSeoTags: (data) => {
            return {
                title: 'Statistika - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },
            (fetchFunction, match) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }


                return fetchFunction(`${baseUrl}/statistics/store/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken

                    },
                }).then(res => res.json()).then((result) => {
                    if (!result.error)
                        return {
                            statisticsData: result
                        }

                })

            },

        ]
    },
    {
        path: "/shop/:storeAlias/admins",
        exact: true,
        component: StoreAdminsPage,
        generateSeoTags: (data) => {
            return {
                title: 'Administratori - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },
            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }


                return fetchFunction(`${baseUrl}/stores/admins/` + match.params.storeAlias, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken

                    },
                    body: JSON.stringify({
                        page: query.page ? query.page : 0,
                        search: query.search ? query.search : null
                    })

                }).then(res => res.json()).then((result) => {
                    return {
                        items: result.items,
                        total: result.total
                    }

                })

            },

        ]
    },
    {
        path: "/shop/:storeAlias/admins/:id",
        exact: true,
        component: admin,
        generateSeoTags: (data) => {
            return {
                title: 'Administratori - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },

        ]
    },

    {
        path: "/shop/:storeAlias/products/:id",
        exact: true,
        component: StoreProductPage,
        generateSeoTags: (data) => {
            return {
                title: 'Artikli - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }

                })

            },

        ]
    },
    {
        path: "/shop/:storeAlias/settings",
        exact: true,
        component: StoreSettingsPage,
        generateSeoTags: (data) => {
            return {
                title: 'Podešavanja - ' + (data.storeData && data.storeData.name),
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match) => {
                return fetchFunction(`${baseUrl}/stores/get/` + match.params.storeAlias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        storeData: result
                    }
                })

            },

        ]
    },



    {
        path: "/account/edit",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: EditAccountPage,

        generateSeoTags: (data) => {
            return {
                title: 'Podešavanje',
            }
        },
        loadData: [

        ]
    }, {
        path: "/account/orders",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: DownloadsPage,
        generateSeoTags: (data) => {
            return {
                title: 'Moje narudzbe',
            }
        },
        loadData: [
            (fetchFunction, match, pathname, query) => {
                let authToken, cart;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }
                if (!query.page) {
                    query.page = 0;
                }
                if (!query.sort) {
                    query.sort = null;
                }

                return fetchFunction(`${baseUrl}/user/orders/${query.page}/${query.sort}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        items: result.items,
                        total: result.total
                    }
                })
            },

        ]
    }, {
        path: "/account/reviews",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: ReviewsPage,
        generateSeoTags: (data) => {
            return {
                title: 'Ocjene',
            }
        },

        loadData: [

            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }

                return fetchFunction(`${baseUrl}/user/reviews`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        items: result
                    }
                })
            },
        ]
    }, {
        path: "/account/create-store",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: AccountStorePage,
        generateSeoTags: (data) => {
            return {
                title: 'Otvorite Shop',
            }
        },

        loadData: [

        ]
    },

    {
        path: "/account/categories",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: AccountCategoriesPage,
        generateSeoTags: (data) => {
            return {
                title: 'Kategorije',
            }
        },

        loadData: [

        ]
    },

    {
        path: "/account/categories/:id",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: AccountCategoryPage,
        generateSeoTags: (data) => {
            return {
                title: 'Kategorije',
            }
        },

        loadData: [

        ]
    },
    {
        path: "/account/news",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: AccountNewsPage,
        generateSeoTags: (data) => {
            return {
                title: 'Novosti',
            }
        },

        loadData: [

        ]
    },
    {
        path: "/account/pages",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: AccountPagesPage,
        generateSeoTags: (data) => {
            return {
                title: 'Stranice',
            }
        },

        loadData: [

        ]
    },
    {
        path: "/account/statistics",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: AdminStatistics,
        generateSeoTags: (data) => {
            return {
                title: 'Statistika',
            }
        },

        loadData: [
            (fetchFunction, match) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }


                return fetchFunction(`${baseUrl}/statistics`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken

                    },
                }).then(res => res.json()).then((result) => {
                    if (!result.error)
                        return {
                            statisticsData: result
                        }

                })

            },

        ]
    },

    {
        path: "/account/pages/:id",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: AccountPageItemPage,
        generateSeoTags: (data) => {
            return {
                title: 'Stranice',
            }
        },

        loadData: [

        ]
    },

    {
        path: "/account/news/:id",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: AccountNewsItemPage,
        generateSeoTags: (data) => {
            return {
                title: 'Novosti',
            }
        },

        loadData: [

        ]
    },
    {
        path: "/account/users",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: AccountUsersPage,
        generateSeoTags: (data) => {
            return {
                title: 'Korisnici',
            }
        },

        loadData: [
            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }

                return fetchFunction(`${baseUrl}/users/all`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({
                        page: query.page ? query.page : 0,
                        search: query.search ? query.search : null
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        items: result.items,
                        total: result.total
                    }
                })

            },

        ]
    },
    {
        path: "/account/users/:id",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: editUserPage,
        generateSeoTags: (data) => {
            return {
                title: 'Korisnici',
            }
        },

        loadData: [

        ]
    },
    {
        path: "/account/stores",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: storesPage,
        generateSeoTags: (data) => {
            return {
                title: 'Radnje',
            }
        },

        loadData: [
            (fetchFunction, match, pathname, query) => {
                let authToken = null;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                }

                return fetchFunction(`${baseUrl}/stores/all`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({
                        page: query.page ? query.page : 0,
                        search: query.search ? query.search : null
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        items: result.items,
                        total: result.total
                    }
                })

            },

        ]
    },
    {
        path: "/account/stores/:id",
        exact: true,
        loginNeeded: true,
        preAuthComponent: LoginPage,
        component: editStorePage,
        generateSeoTags: (data) => {
            return {
                title: 'Radnja',
            }
        },

        loadData: [

        ]
    },



    {
        path: "/cart",
        exact: true,
        component: CartPage,
        generateSeoTags: (data) => {
            return {
                title: 'Korpa',
            }
        },

        loadData: [

            (fetchFunction, match, pathname, query) => {
                let authToken, cart;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                    cart = localStorage.getItem('cart');
                    if (!cart) {
                        cart = [];
                    } else {
                        cart = JSON.parse(cart);
                    }
                }

                return fetchFunction(`${baseUrl}/cart`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({ cart })
                }).then(res => res.json()).then((result) => {

                    return {
                        cart: result,
                    }
                })
            },
            (fetchFunction, match, pathname, query) => {
                let authToken, cart;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                    cart = localStorage.getItem('cart');
                    if (!cart) {
                        cart = [];
                    } else {
                        cart = JSON.parse(cart);
                    }
                }

                return fetchFunction(`${baseUrl}/cart/shipping`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({ cart })
                }).then(res => res.json()).then((result) => {

                    return {
                        shippingPrice: result.shipping,
                    }
                })
            },



        ]
    }, {
        path: "/checkout",
        exact: true,
        component: CheckoutPage,
        generateSeoTags: (data) => {
            return {
                title: 'Korpa',
            }
        },

        loadData: [

            (fetchFunction, match, pathname, query) => {
                let authToken, cart;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                    cart = localStorage.getItem('cart');
                    if (!cart) {
                        cart = [];
                    } else {
                        cart = JSON.parse(cart);
                    }
                }

                return fetchFunction(`${baseUrl}/cart`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({ cart })
                }).then(res => res.json()).then((result) => {

                    return {
                        cart: result,
                    }
                })
            },
            (fetchFunction, match, pathname, query) => {
                let authToken, cart;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                    cart = localStorage.getItem('cart');
                    if (!cart) {
                        cart = [];
                    } else {
                        cart = JSON.parse(cart);
                    }
                }

                return fetchFunction(`${baseUrl}/cart/shipping`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({ cart })
                }).then(res => res.json()).then((result) => {

                    return {
                        shippingPrice: result.shipping,
                    }
                })
            },

        ]
    },{
        path: "/checkout-done",
        exact: true,
        component: CheckoutPage,
        generateSeoTags: (data) => {
            return {
                title: 'Korpa',
            }
        },

        loadData: [

            (fetchFunction, match, pathname, query) => {
                let authToken, cart;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                    cart = localStorage.getItem('cart');
                    if (!cart) {
                        cart = [];
                    } else {
                        cart = JSON.parse(cart);
                    }
                }

                return fetchFunction(`${baseUrl}/cart`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({ cart })
                }).then(res => res.json()).then((result) => {

                    return {
                        cart: result,
                    }
                })
            },
            (fetchFunction, match, pathname, query) => {
                let authToken, cart;
                if (typeof localStorage !== 'undefined') {
                    authToken = 'Bearer ' + localStorage.getItem('authToken');
                    cart = localStorage.getItem('cart');
                    if (!cart) {
                        cart = [];
                    } else {
                        cart = JSON.parse(cart);
                    }
                }

                return fetchFunction(`${baseUrl}/cart/shipping`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': authToken
                    },
                    body: JSON.stringify({ cart })
                }).then(res => res.json()).then((result) => {

                    return {
                        shippingPrice: result.shipping,
                    }
                })
            },

        ]
    }, {
        path: "/login",
        exact: true,
        redirectUser: '/account/orders',
        component: LoginPage,
        generateSeoTags: (data) => {
            return {
                title: 'Login',
            }
        },

        loadData: [

        ]
    }, {
        path: "/account/verify/:uid/:emailVerificationCode",
        exact: true,
        redirectUser: '/account/orders',
        component: EmailVerifyPage,
        generateSeoTags: (data) => {
            return {
                title: 'Email verifikacija',
            }
        },

        loadData: [

        ]
    }, {
        path: "/register",
        exact: true,
        redirectUser: '/account/orders',
        generateSeoTags: (data) => {
            return {
                title: 'Registracija',
            }
        },

        component: RegisterPage,
        loadData: [

        ]
    }, {
        path: "/reset-password",
        exact: true,
        redirectUser: '/account/orders',
        generateSeoTags: (data) => {
            return {
                title: 'Resetuj lozinku',
            }
        },

        component: ResetPasswordPage,
        loadData: [

        ]
    }, {
        path: "/reset-password/:uid/:resetPasswordVerificationCode",
        exact: true,
        redirectUser: '/account/orders',
        generateSeoTags: (data) => {
            return {
                title: 'Reset lozinke',
            }
        },

        component: ChangePasswordPage,
        loadData: [

        ]
    }, {
        path: "/blog",
        exact: true,
        component: BlogPage,
        generateSeoTags: (data) => {
            return {
                title: 'Blog',
            }
        },

        loadData: [
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/blog/categories`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        categories: result,
                    }
                })

            },
            (fetchFunction, match, pathname, query) => {
                if (!query.page) {
                    query.page = 0;
                }

                return fetchFunction(`${baseUrl}/blog/fetch/${query.page}/14/null`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        items: result.items,
                        total: result.total
                    }
                })

            },

        ]
    }, {
        path: "/blog/:alias",
        exact: true,
        component: BlogDetailPage,
        generateSeoTags: (data) => {
            return {
                title: (data.data && data.data.title) + ' | Blog',
                description: data.storeData && data.storeData.aboutUs,
                'og:image': data.storeData && data.storeData.profilePhoto
            }
        },

        loadData: [
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/blog/categories`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        categories: result,
                    }
                })

            },

            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/blog/detail/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/blog/fetch/0/3/${match.params.category}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        similar: result.items,
                    }
                })

            },


        ]
    }, {
        path: "/contact",
        exact: true,
        component: ContactPage,
        generateSeoTags: (data) => {
            return {
                title: 'Otvorite Shop'
            }
        },

        loadData: [
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/blog/latest`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            },

        ]
    }, {
        path: "/page/:alias",
        exact: true,
        component: DynamicPage,
        generateSeoTags: (data) => {
            return {
                title: 'Stranica'
            }
        },

        loadData: [
            (fetchFunction, match, pathname, query) => {
                return fetchFunction(`${baseUrl}/page/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },


        ]
    }
]
