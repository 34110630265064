import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';
import ReactPaginate from 'react-paginate';


import Article from '../../components/articles/downloadArticle';
import BlogArticle from '../../components/articles/blogArticle';


import bg from '../../assets/images/category-bg.jpg';
import rightArrow from '../../assets/svg/right-arrow.svg';
import user from '../../assets/svg/user.svg';
import emptyCart from '../../assets/svg/empty-cart.svg';


import solution1 from '../../assets/images/solution1.png';


class DownloadsPage extends Component {
    constructor(props) {
        super(props);
        this.getSearchParams = this.getSearchParams.bind(this);
        this.generateSearchLink = this.generateSearchLink.bind(this);

        this.state = {
            ...props.initialData,
            testPageNumber: 0
        };
    }

    componentDidMount() {

        window.scrollTo(0, 0);


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname, this.getSearchParams()).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }
    }

    componentDidUpdate(prevProps) {

        console.log(this.props[0].location.search)

        if (prevProps[0].location.pathname != this.props[0].location.pathname || prevProps[0].location.search != this.props[0].location.search) {
            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname, this.getSearchParams()).then((data) => {
                    this.setState({
                        ...data,
                        testPageNumber: this.getSearchParams().page? Number(this.getSearchParams().page) : 0 
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state));
                    })
                })
            }

        }
    }

    getSearchParams() {
        let brokenParams = this.props[0].location.search.replace('?', '').split('&');
        let params = {};
        for (let i = 0; i < brokenParams.length; i++) {
            params[brokenParams[i].split('=')[0]] = brokenParams[i].split('=')[1];
        }

        return params;
    }

    generateSearchLink(name, value, isValueArray) {
        let params = this.getSearchParams();

        if (!value) {
            delete params[name];
        } else {
            if (isValueArray) {
                if (!params[name]) {
                    params[name] = [];
                }


                if (params[name].indexOf(value) !== -1) {
                    params[name].splice(params[name].indexOf(value), 1);
                } else {
                    params[name].push(value);
                }
                params[name] = params[name].join(',');
            } else {
                params[name] = value;
            }
        }


        let paramsGroup = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                paramsGroup.push(`${key}=${params[key]}`)
            }
        }


        return `?${paramsGroup.join('&')}`;
    }


    render() {
        let params = this.getSearchParams();

        return (
            <div className="account-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="4" className="user-info">
                                <Isvg src={user} />
                                <div>
                                    <h1>Moj nalog</h1>
                                    <p className="email">{this.props.uData && this.props.uData.email}</p>
                                    <button onClick={() => this.props.signOut()}>Izloguj se</button>
                                </div>
                            </Col>
                            <Col lg={{ size: 8 }} className="user-nav">
                                <Link to='/account/orders'><button className="active">MOJE NARUDŽBE</button></Link>
                                {
                                    this.props.uData && this.props.uData.storeAlias ?
                                        <Link to={`/shop/${this.props.uData.storeAlias}`}><button>MOJ SHOP</button></Link>

                                        :
                                        null
                                }
                                <Link to='/account/edit'><button>PODEŠAVANJA</button></Link>
                                <Link to='/account/reviews'><button >OCJENE</button></Link>
                                {this.props.uData && this.props.uData.permissions && this.props.uData.permissions.indexOf('*') !== -1 ? <Link to='/account/categories'><button >ADMINISTRACIJA</button></Link> : null}

                            </Col>
                        </Row>

                    </Container>
                </div>


                <section className="downloads-section">
                    <Container>
                        <Row>
                            <Col lg={this.state.items && this.state.items.length ? '12' : '12'} className="area">
                                <div className="top">
                                    <h2>Moje narudžbe</h2>
                                    {this.state.items && this.state.items.length ?
                                        <div className="sort">
                                            <span>Sort:</span>
                                            <ul>
                                                <li className={!params.sort ? 'active' : null}><Link to={this.generateSearchLink('sort', null)}><button>Datum kupovine</button> </Link></li>
                                                <li className={params.sort == 'title' ? 'active' : null}><Link to={this.generateSearchLink('sort', 'title')}><button>Naslov</button> </Link></li>
                                                <li className={params.sort == 'price' ? 'active' : null}><Link to={this.generateSearchLink('sort', 'price')}><button>Cijena</button> </Link></li>
                                            </ul>
                                            <UncontrolledDropdown>
                                                <DropdownToggle caret>
                                                    Newest
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Newest</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                        </div>
                                        :
                                        null
                                    }
                                </div>

                                {this.state.items && this.state.items.length ?
                                    <>

                                        <Row className="articles">
                                            {
                                                this.state.items && this.state.items.map((item, idx) => {
                                                    return (
                                                        <Col lg={12}>
                                                            <Article
                                                                {...item}
                                                                listView={true}
                                                            />
                                                        </Col>

                                                    )
                                                })
                                            }
                                        </Row>
                                        {this.state.total > 20 ?
                                            <Row>
                                                <Col lg="12">
                                                    <ReactPaginate
                                                        forcePage={this.state.testPageNumber} //force on page numer from parameters, solves problem with browser back button and pagnation
                                                        previousLabel={''}
                                                        nextLabel={''}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.state.total / 20}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={2}
                                                        onPageChange={(page) => { this.props[0].history.push(this.generateSearchLink('page', page.selected)) }}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                        hrefBuilder={(page) => { return this.generateSearchLink('page', page) }}
                                                    />

                                                </Col>

                                            </Row>

                                            :
                                            null
                                        }
                                    </>
                                    :
                                    <div className="no-items">
                                        <Isvg src={emptyCart} />
                                        <h6>Niste kupili nijedan artikal.</h6>
                                        <p>Pretražite naš sajt i pronađite željene artikle.</p>
                                        <Link to='/'><button>Pretraži artikle <Isvg src={rightArrow} /> </button></Link>
                                    </div>

                                }


                            </Col>
                            {/*this.state.items && this.state.items.length ?

                                <Col lg="3">
                                    <div className="light-blue-box">
                                        <h6>Download</h6>
                                        <p>You should download your purchases
                                        immediately as items may b
 removed from time to time.</p>

                                    </div>
                                    <div className="dark-blue-box">
                                        <h6>Do You Need Website?</h6>
                                        <p>We are proud to partner with
                                        nova media. to offer you great
                                        solutions with unique design and
                                        latest technologies.

</p>
                                        <a href="#">Get Website</a>

                                    </div>
                                </Col>
                                :
                                null*/
                            }
                        </Row>
                    </Container>

                </section>




            </div>
        );
    }
}

export default Page(DownloadsPage);