import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

/*header*/



import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';



import logo from '../assets/svg/logo.svg';
import user from '../assets/svg/header-account.svg';
import cart from '../assets/svg/header-cart.svg';
import search from '../assets/svg/search.svg';
import accountIcon from '../assets/svg/user.svg';

import phoneIcon from '../assets/svg/phone.svg';
import mailIcon from '../assets/svg/email.svg';
import fbIcon from '../assets/svg/facebook.svg';
import instagramIcon from '../assets/svg/instagram.svg';


class Header extends Component {
  constructor(props) {
    super(props);


    this.state = {
      yScroll: 0
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const yScroll =
      document.body.scrollTop || document.documentElement.scrollTop


    this.setState({
      yScroll: yScroll,
      scrollHeader: yScroll < this.state.yScroll,
    })
  }



  render() {

    const isNotAuthPage = this.props[0].location.pathname.indexOf('/reset-password') === -1 &&  this.props[0].location.pathname.indexOf('/login') === -1 && this.props[0].location.pathname.indexOf('/register') && !(!this.props.uData && this.props[0].location.pathname.indexOf('/account') !== -1);

    return (
      <header className={this.state.yScroll > 20 && this.state.scrollHeader ? 'scroll-header' : this.state.yScroll < 20 ? '' : 'hide-header'} >
        {isNotAuthPage ? <Container className="header-top">
          <Row>
            <Col lg="6" className="left">
              <div> <Isvg src={phoneIcon} /> +387 66 372 314</div>
              <div> <a href="mailto:info@eeshop.ba"> <Isvg src={mailIcon} /> eeshop@euroexpress.ba </a> </div>

            </Col>
            <Col lg="6" className="right">
              <ul>
                <li><a href='https://euroexpress.ba/pra%C4%87enje-po%C5%A1iljke' target="_blank">Pratite pošiljku</a></li>
                <li><Link to='/page/pomoc'>Pomoć</Link></li>
                <li><Link to='/contact'>Otvorite svoj Shop</Link></li>
                <li>
                  Pratite nas
                  <a target="_blank" href="https://www.facebook.com/eeshop.ba/"><Isvg src={fbIcon} /></a>
                  <a target="_blank" href="https://instagram.com/eeshop.ba"><Isvg src={instagramIcon} /></a>
                </li>

              </ul>

            </Col>
          </Row>
        </Container>
          :
          null
        }
        <Container>
          <Row>
            <Col xs="2" className={this.state.mobileNavigation ? 'hamburger hamburger-animation' : 'hamburger'}>
              <button onClick={() => this.setState({ mobileNavigation: !this.state.mobileNavigation })}>
                <div></div>
                <div></div>
                <div></div>
              </button>
            </Col>
            <Col lg="3" xs="8" className={isNotAuthPage ? "logo" : 'logo white-logo'}>
              <Link to='/' className="desktop-logo"><Isvg src={logo} /></Link>
              <Isvg src={logo} />
              <input type="text" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} onKeyUp={(e) => {
                if (e.keyCode == 13) {
                  e.preventDefault();
                  this.props[0].history.push(`/kategorije${this.state.search ? `?search=${encodeURIComponent(this.state.search)}` : ''}`)
                }
              }}

              />

              <button className="search">
                <Isvg src={search} />
              </button>

            </Col>
            <Col xs="2" className="mobile-actions">
              <Link to='/cart'>
                <button className="cart">
                  <Isvg src={cart} />
                </button>
              </Link>
            </Col>

            <Col lg="9" xs="6" sm="5" className="navigation">
              <ul className={isNotAuthPage ? '' : 'login-nav'}>
                <li className={this.props[0].location.pathname == '/' ? "active" : null}  ><Link to='/'>Početna</Link></li>
                <li className={this.props[0].location.pathname == '/kategorije' ? "active" : null}><Link to='/kategorije'>Kategorije</Link></li>
                {isNotAuthPage ? <li className={this.props[0].location.pathname.indexOf('/shop') !== -1 ? "active" : null}><Link to='/shopovi'>Shop-ovi</Link></li> : null}
                {isNotAuthPage ? <li className={this.props[0].location.pathname == '/kategorije' ? "active" : null}><Link to='/kategorije'>Najnovije</Link></li> : null}
               {/* <li className={this.props[0].location.pathname == '/blog' ? "active" : null}><Link to='/blog'>Blog</Link></li>*/}

                {isNotAuthPage ?
                  <>
                    <li className={this.props[0].location.pathname.indexOf('/account') !== -1 ? "mobile-visible active account-button" : "mobile-visible account-button"}><Link to='/login'><Isvg src={user} /> {this.props.uData ? 'Nalog' : 'Login/Registracija'}</Link></li>
                    <li className="mobile-visible"><Link to='/cart'><Isvg src={cart} /> Korpa                   {
                    this.props.cartItems ?
                    <div className="cart-count">{this.props.cartItems}</div>
                    :
                    null
                  }
</Link></li>
                  </>
                  :
                  null
                }
              </ul>
            </Col>

          </Row>
        </Container>

        <div className={this.state.mobileNavigation ? "mobile-menu-wrap mobile-menu-open" : "mobile-menu-wrap"}>
          <div className="overlay" onClick={() => this.setState({ mobileNavigation: null })}></div>
          <div className="mobile-menu">
            <div className="user-info">
              <Isvg src={accountIcon} />
              <h6>{this.props.uData && this.props.uData.name ? this.props.uData.name : 'Nalog'}</h6>
              <p>{this.props.uData ? this.props.uData.email : 'Sign up or Login to make purchase.'} </p>
              <div className={this.props.uData ? "buttons" : "buttons buttons-login"}>
                {this.props.uData ?
                  <>
                    <Link to={'/account/edit'}><button>Nalog</button></Link>
                    <Link to={'/register'}> <button>Narudžbe</button></Link>
                    <button onClick={() => this.props.signOut()}>Odjavi se</button>

                  </>
                  :
                  <>
                    <Link to={'/login'}><button>Prijava</button></Link>
                    <Link to={'/register'}><button>Registracija</button></Link>

                  </>
                }
              </div>
            </div>
            <ul>
              <li> <Link to='/'>Početna</Link> </li>
              <li> <Link to='/cart'>Korpa</Link> </li>
              <li> <Link to='/kategorije'>Kategorije</Link> </li>
              <li> <Link to='/shopovi'>Shop-ovi</Link> </li>
              <li> <Link to='/kategorije'>Najnovije</Link> </li>
              <li> <Link to='#'> </Link> </li>
              <li> <Link to='#'> </Link> </li>
              <li> <Link to='#'> </Link> </li>
              <li> <a href='https://euroexpress.ba/pra%C4%87enje-po%C5%A1iljke' target="_blank">Pratite pošiljku</a> </li>
              <li> <Link to='/page/pomoc'>Pomoć</Link> </li>
              <li> <Link to='/contact'>Otvorite svoj Shop</Link> </li>
             {/* <li> <Link to='/blog'>Blog</Link> </li> */}
            </ul>
          </div>
        </div>

      </header>
    );
  }
}

export default Header;