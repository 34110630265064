import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';


import {
    Container,
    Row,
    Col,

} from 'reactstrap';


import Article from '../../components/articles/cartArticle';
import BlogArticle from '../../components/articles/blogArticle';


import rightArrow from '../../assets/svg/right-arrow.svg';
import cart from '../../assets/svg/cart.svg';
import emptyCart from '../../assets/svg/empty-cart.svg';

import lock from '../../assets/svg/secure.svg';
import {baseUrl} from "../../helpers/constants";

class CartPage extends Component {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
        this.emptyCart = this.emptyCart.bind(this);

        this.state = {
            cart: [],
            ...props.initialData
        };
    }


    init() {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }
    }

    removeFromCart(id) {
        if (this.props.uData) {
            fetch(`${baseUrl}/cart/remove/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(res => res.json()).then((result) => {
                this.init()
                this.props.fetchCartInfo();
            })

        } else {
            let cart = localStorage.getItem('cart');
            if (!cart) {
                cart = [];
            } else {
                cart = JSON.parse(cart);
            }

            let idx = -1;
            for(let i=0;i<cart.length;i++){
                if (cart[i].productId == id){
                    idx = i;
                    break;
                }
            }

            if (idx !== -1)
                cart.splice(idx, 1);

            localStorage.setItem('cart', JSON.stringify(cart));
            this.init();
            this.props.fetchCartInfo();
        }
    }

    emptyCart() {
        if (this.props.uData) {
            fetch(`${baseUrl}/cart/empty`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(res => res.json()).then((result) => {
                this.init()
                this.props.fetchCartInfo();

            })

        } else {

            localStorage.setItem('cart', JSON.stringify([]));
            this.init();
            this.props.fetchCartInfo();

        }

    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.init();
    }


    render() {

        let total = 0;
        if (this.state.cart) {
            for (let i = 0; i < this.state.cart.length; i++) {
                total += parseFloat(this.state.cart[i].price * this.state.cart[i].quantity);
            }
        }


        return (
            <div className="cart-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="12" className="cart-info">
                                <Isvg src={cart} />
                                <div>
                                    <h1>Korpa</h1>
                                    <p>Imate {this.state.cart.length} {this.state.cart.length == 1 ? 'artikal' : 'artikla'} u korpi</p>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>


                <section className="downloads-section">
                    <Container>
                        <Row>
                            <Col lg={this.state.cart && this.state.cart.length ? "9" : "12"} className="area">
                                {this.state.cart && this.state.cart.length ?
                                    <div className="top">
                                        <h2>Artikli u korpi</h2>
                                        <div className="actions">
                                            <button>Nastavi kupovinu</button>
                                            <button onClick={this.emptyCart}>Izprazni korpu</button>

                                        </div>
                                    </div>
                                    :
                                    <div className="no-items">
                                        <Isvg src={emptyCart} />
                                        <h6>Vaša korpa je prazna</h6>
                                        <p>Pretražite naš sajt i pronađite željene artikle.</p>
                                        <Link to='/'><button>Pretraži artikle <Isvg src={rightArrow} /> </button></Link>
                                    </div>
                                }

                                <Row className="articles">
                                    {
                                        this.state.cart && this.state.cart.map((item, idx) => {
                                            return (
                                                <Col lg={12}>
                                                    <Article
                                                        {...item}
                                                        updateQuantity={(quantity) => this.props.updateCart(item, quantity, this.init)}
                                                        handleRemove={() => this.removeFromCart(item._id)}
                                                        listView={true}
                                                    />
                                                </Col>

                                            )
                                        })
                                    }
                                    {this.state.cart && this.state.cart.length ?

                                        <Col lg="12">
                                            <Link to='/checkout'>
                                                <button className="checkout-button">Nastavi dalje <Isvg src={rightArrow} /></button>
                                            </Link>
                                        </Col>

                                        :

                                        null
                                    }
                                </Row>



                            </Col>
                            {this.state.cart && this.state.cart.length ?
                                <Col lg={{ size: 3 }}>
                                    <div className="info-box">
                                        <h6>Ukupno u korpi</h6>
                                        <div className="item">
                                            <span className="name">Artikli</span>
                                            <span className="price">{total.formatPrice(2)} KM</span>
                                        </div>

                                        <div className="item">
                                        </div>
                                        <div className="item">
                                            <span className="name">Dostava</span>
                                            <span className="price">{this.state.shippingPrice ? this.state.shippingPrice.formatPrice(2) + ' KM' : <span style={{textAlign: 'right', fontSize: 12}}>Troškove dostave određuje prodavnica.</span>}</span>
                                        </div>
                                        <div className="total">
                                            <span className="name">Ukupno:</span>
                                            <span className="price">{(total + this.state.shippingPrice).formatPrice(2)} KM</span>
                                        </div>

                                        <p><Isvg src={lock} />Sigurna kupovina</p>
                                    </div>
                                </Col>

                                :
                                null
                            }
                        </Row>
                    </Container>

                </section>



            </div >
        );
    }
}

export default Page(CartPage);
