import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/header';
import Footer from '../components/footer';
import AddtoCartModal from '../components/addToCartModal';

import solution1 from '../assets/images/solution1.png';

export const DefaultLayout = (Wrapped) => (props) => {
    return (
        <div className={"wrapper"}>
            <Header {...props} />
            <Wrapped {...props} />
            <Footer {...props} />
            {props.productAddedToCart &&
                <AddtoCartModal
                    {...props.productAddedToCart}
                    hideAddToCartModal={props.hideAddToCartModal}
                />
            }
        </div>
    );
};

export default DefaultLayout;