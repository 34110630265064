import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import {
    Container,
    Row,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';

import Image from '../components/image';

import Article from '../components/articles/article';
import BlogArticle from '../components/articles/blogArticle';

import rightArrow from '../assets/svg/right-arrow.svg';

import Slider from "react-slick";
import postman from '../assets/svg/EE_kurir.svg';


class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchCategory: 'Sve kategorije',
            ...props.initialData
        };
    }

    componentDidMount() {

        window.scrollTo(0, 0);


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }





    }



    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: typeof window !== "undefined" && window.innerWidth < 768 ? 2 : 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000
        };

        return (
            <div className="home-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="5" className="info">
                            <Isvg src={postman} />

                                <div >
                                    <h1>Dobrodošli na EE shop!</h1>
                                    {/*<h2>EuroExpress brze pošte!</h2>*/}
                                    <p>Želimo da brzo i jednostavno pronađete sve što vam je potrebno na jednom mjestu, a mi ćemo u saradnji sa našim poslovnim partnerima vaše želje dostaviti vama na kućni prag.</p>
                                </div>
                            </Col>
                            <Col lg={{ size: 7 }} className="search-container">
                                <h5>Pronađite željeni artikal</h5>
                                <div className="search-wrap">
                                    <input type="text" placeholder="Unesite pojam za pretragu" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} onKeyUp={(e) => {
                                        if (e.keyCode == 13) {
                                            e.preventDefault();
                                            this.props[0].history.push(`/kategorije${this.state.searchBreadcrumb ? this.state.searchBreadcrumb : ''}${this.state.search ? `?search=${encodeURIComponent(this.state.search)}` : ''}`)
                                        }
                                    }} />
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret>
                                            {this.state.searchCategory}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => this.setState({ searchBreadcrumb: null, searchCategory: 'Sve kategorije' })}>{this.state.searchCategory}</DropdownItem>

                                            {
                                                this.state.categories && this.state.categories.map((item, idx) => {
                                                    return (
                                                        <DropdownItem onClick={() => this.setState({ searchBreadcrumb: item.breadcrumb, searchCategory: item.name })}>{item.name}</DropdownItem>

                                                    )
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <button className="button" onClick={() => this.props[0].history.push(`/kategorije${this.state.searchBreadcrumb ? this.state.searchBreadcrumb : ''}${this.state.search ? `?search=${encodeURIComponent(this.state.search)}` : ''}`)}>TRAŽI <Isvg src={rightArrow} /> </button>

                                </div>
                                <div className="recomended">
                                    <span>Preporučene pretrage:</span>
                                    <ul>
                                        <li><Link to='/kategorije/mobilni-uredjaji'>Mobilni telefon</Link></li>
                                        <li><Link to='/kategorije/ljepota-i-zdravlje'>Ljepota i zdravlje</Link></li>
                                        <li><Link to='/kategorije/vozila/automobili'>Auto dijelovi</Link></li>
                                        <li><Link to='/kategorije/odjeca-i-obuca'>Odjeća i obuća</Link></li>

                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <div className="categories-wrap">
                            <Col lg="12">
                                <Row className="categories">
                                    {
                                        this.state.categories && this.state.categories.map((item, idx) => {
                                            return (
                                                <Col lg="2" xs="2" key={idx} className="category-item">
                                                    <Link to={`/kategorije${item.breadcrumb}`}>
                                                        <div className="item">
                                                            <Image src={item.image} />
                                                            <h6>{item.name}</h6>
                                                        </div>
                                                    </Link>
                                                </Col>

                                            )
                                        })
                                    }

                                </Row>
                            </Col>
                        </div>
                        {/*<Row>
                            <Col xs="12" className="search-container-mobile">
                                <h5>Find Your Solution</h5>
                                <div className="search-wrap">
                                    <input type="text" placeholder="What are you looking for?" />
                                    <button className="button">SEARCH <Isvg src={rightArrow} /> </button>

                                </div>
                            </Col>

                        </Row>
                        */}
                    </Container>
                </div>


                <section className="section-stores">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <h3> Izdvojeni Shop-ovi</h3>
                            </Col>
                            <Col lg="12">
                                <Slider {...settings}>
                                    {
                                        this.state.promotedStores && this.state.promotedStores.map((item, idx) => {
                                            return (
                                                <div className="item" key={idx}>
                                                    <img src={item.profilePhoto} onClick={() => this.props[0].history.push(`/shop/${item.alias}`)} />
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>

                            </Col>
                        </Row>
                    </Container>

                </section>


                <section className="section-articles">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <h3>Izdvojeni artikli</h3>
                            </Col>

                        </Row>
                        <Row className="articles">
                            {
                                this.state.promotedProducts && this.state.promotedProducts.map((item, idx) => {
                                    return (
                                        <Col xl="2" lg="3" md="4" xs="6" key={idx}>
                                            <Article
                                                {...item}
                                                addToCart={this.props.addToCart}
                                            />
                                        </Col>

                                    )
                                })
                            }


                        </Row>
                        <Row>

                            <Col lg="12" className="button-wrap">
                                <Link to='/kategorije?sort=best-rated'>
                                    <button className="button">Pogledajte sve izdvojene artikle <Isvg src={rightArrow} /></button>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </section>



                <section className="section-articles">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <h3>Najnoviji artikli</h3>
                            </Col>

                        </Row>
                        <Row className="articles">
                            {
                                this.state.latestProducts && this.state.latestProducts.map((item, idx) => {
                                    return (
                                        <Col xl="2" lg="3" md="4" xs="6" key={idx}>
                                            <Article
                                                {...item}
                                                addToCart={this.props.addToCart}
                                            />
                                        </Col>

                                    )
                                })
                            }
                        </Row>
                        <Row>

                            <Col lg="12" className="button-wrap">
                                <Link to='/kategorije'>
                                    <button className="button">Pogledajte sve nove artikle <Isvg src={rightArrow} /></button>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </section>



            </div>
        );
    }
}

export default Page(HomePage);