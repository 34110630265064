import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

import Text from './fields/textIcon';

import Check from './fields/check';
import Select from './fields/select';

import mail from '../../assets/svg/mail.svg';
import lock from '../../assets/svg/lock.svg';
import user from '../../assets/svg/user-icon.svg';

import rightChevron from '../../assets/svg/right-arrow.svg';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';



const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    icon,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            icon={icon}
            errorText={touched && error}
            error={touched && error}
            type={type}
            {...input}
        />
    )


const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        >{children}</Select>
    )

const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Check
            label={label}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )



class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit} >
                <Container>
                <Row>
                    <Col lg="6">
                        <h6>Podaci naloga</h6>
                        <Field
                            name="name"
                            component={renderTextField}
                            label="Ime i prezime"
                            placeholder='Steve Johnson'

                        ></Field>
                        <Field
                            name="email"
                            //type="email"
                            component={renderTextField}
                            label="E-mail Adresa"
                            validate={[required]}
                            placeholder='name@yourdomain.com'

                        ></Field>
                        <h6>Adresa za dostavu</h6>
                        <Row>
                            <Col lg="6">
                                <Field
                                    name="shippingAddress.name"
                                    component={renderTextField}
                                    label="Ime i prezime"
                                    validate={[required]}
                                ></Field>
                            </Col>
                            <Col lg="6">
                                <Field
                                    name="shippingAddress.phoneNumber"
                                    component={renderTextField}
                                    label="Telefon"
                                    validate={[required]}

                                ></Field>

                            </Col>
                            <Col lg="6">
                                <Field
                                    name="shippingAddress.address"
                                    component={renderTextField}
                                    label="Adresa"
                                    validate={[required]}

                                ></Field>

                            </Col>
                            <Col lg="6">
                                <Field
                                    name="shippingAddress.city"
                                    component={renderTextField}
                                    label="Grad"
                                    validate={[required]}
                                ></Field>

                            </Col>
                        </Row>

                    </Col>
                    <Col lg={{ size: 5, offset: 1 }}>
                        <h6>E-mail podešavanja</h6>

                        <Field
                            name="emailSettings.newsletter"
                            component={renderCheckField}
                            label="Our Newsletter emails"

                        ></Field>
                        <Field
                            name="emailSettings.itemNotifications"
                            component={renderCheckField}
                            label="Item comment notifications"

                        ></Field>

                        <h6 className="password-settings-title">Lozinka</h6>
                        {!this.state._changePassword ?

                            <button className="submit-button" type="button" className="change-password-button" onClick={() => this.setState({ _changePassword: true })}>Izmjeni lozinku</button>
                            :
                            <>
                                <Field
                                    name="newPassword"
                                    type="password"
                                    component={renderTextField}
                                    label="Nova lozinka"
                                    placeholder='Enter your password'

                                ></Field>

                                <Field
                                    name="newPasswordRetyped"
                                    type="password"
                                    component={renderTextField}
                                    label="Ponovi lozinku"
                                    placeholder='Enter your password'

                                ></Field>
                            </>
                        }

                    </Col>
                    <Col lg="12">
                        <button className="submit-button">Izmjeni nalog <Isvg src={rightChevron} /></button>
                    </Col>
                </Row>
                </Container>
            </form>
        )
    }
}

export default reduxForm({
    form: 'editAccountForm'  // a unique identifier for this form
})(form)
