import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Isvg from 'react-inlinesvg';

import logo from '../assets/svg/footer-logo.svg';

import social1 from '../assets/svg/social1.svg';
import social2 from '../assets/svg/social2.svg';
import social3 from '../assets/svg/social3.svg';
import social4 from '../assets/svg/social4.svg';
import social5 from '../assets/svg/social5.svg';

import rightArrow from '../assets/svg/right-arrow.svg';
import BlogArticle from '../components/articles/blogArticle';

import {
    Container,
    Row,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }




    render() {
        if (this.props[0].location.pathname == '/login' || this.props[0].location.pathname == '/register' || this.props[0].location.pathname == '/reset-password') {
            return null;
        }
        return (
            <>
                {/*<section className="section-blog">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <h3>Čitajte naš blog</h3>
                                <h6>Pregledajte najnovije članke i savjete s našeg bloga</h6>
                            </Col>
                        </Row>

                        <Row className="blog-articles">
                            {
                                this.props.latestBlog && this.props.latestBlog.map((item, idx) => {
                                    return (
                                        <Col lg="4" md="4">
                                            <BlogArticle
                                                {...item}
                                            />
                                        </Col>

                                    )
                                })
                            }
                        </Row>


                        <Row>

                            <Col lg="12" className="button-wrap">
                                <Link to='/blog'>
                                    <button className="button">Pogledajte sve članke <Isvg src={rightArrow} /></button>
                                </Link>
                            </Col>
                        </Row>

                    </Container>
                        </section>*/}

               {/* <section className="section-newsletter">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h3>Pretplati se na Newsletter</h3>
                                <p>Pretplatite se na naš newsletter i primajte<br />naša najnovija obavještenja!</p>
                            </Col>
                            <Col lg="6">
                                {this.state._done ?
                                    <p className="done">Uspješno ste se prijavili na naš newsletter</p>
                                    :
                                    <div className="input">
                                        <input type="text" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} placeholder="Unesite E-mail adresu" />
                                        <button onClick={() => {
                                            fetch('https://api.eeshop.ba/newsletter/subscribe', {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({ email: this.state.email })
                                            }).then((res) => res.json()).then((result) => {
                                                if (!result.error) {
                                                    this.setState({
                                                        _done: true,
                                                        _error: null,
                                                    })
                                                } else {
                                                    this.setState({
                                                        _error: true,
                                                        _done: null
                                                    })
                                                }
                                            })
                                        }}>PRETPLATI SE <Isvg src={rightArrow} /></button>
                                    </div>
                                }
                                {
                                    this.state._error ?
                                        <p className="err">Greška!</p>
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                            </section>*/}

                <footer>
                    <Container>
                        <Row>
                            <Col lg="3" className="logo">
                                <Isvg src={logo} />
                                <p>© 2021 eeshop.ba All rights reserved.<br/>Created by <a href="https://novamedia.agency" target="_blank">nova media.</a></p>
                            </Col>
                            <Col lg="3">
                                <h6>Kompanija</h6>
                                <ul>
                                    <li><Link to='/page/o-nama'>O nama</Link></li>
                                    <li><Link to='/login'>Nalog</Link></li>
                                    <li><Link to='/shopovi'>Shop-ovi</Link></li>
                                </ul>
                            </Col>
                            <Col lg="3">
                                <h6>Pomoć</h6>
                                <ul>
                                    <li><Link to='/page/pomoc'>FAQ</Link></li>
                                    <li><Link to='/page/podrska'>Podrška</Link></li>
                                </ul>
                            </Col>
                            <Col lg="3">
                                <h6>Politika</h6>
                                <ul>
                                    <li><Link to='/page/uslovi-poslovanja'>Uslovi poslovanja</Link></li>
                                    {/*<li><Link to='/page/politika-privatnosti'>Politika privatnosti</Link></li>
                                    <li><Link to='/page/kupovina'>Kupovina</Link></li>*/}
                                    <li><a href='https://files.euroexpress.ba/Opsti_uslovi_poslovanja.pdf' target="_blank">Dostava</a></li>
                                </ul>
                            </Col>
                            <Col lg="12">
                                <div className="spacer"></div>
                            </Col>
                            <Col lg="12" className="social">
                                <a href="https://www.facebook.com/eeshop.ba/" target="_blank"><Isvg src={social1} /></a>
                                <a href="http://instagram.com/eeshop.ba" target="_blank"><Isvg src={social2} /></a>
                               {/* <a href="https://www.youtube.com/channel/UCWnmVbqSlWKGamJWapDa2Wg" target="_blank"><Isvg src={social4} /></a>
                                <a href="https://www.linkedin.com/company/euroexpress-brza-posta" target="_blank"><Isvg src={social5} /></a>*/}
                            </Col>
                        </Row>
                    </Container>

                    <button className="scroll-up" onClick={() => {
                        if (typeof window !== 'undefined') { window.scrollTo({ behavior: 'smooth', top: 0 }); }

                    }}></button>
                </footer>
            </>
        );
    }
}

export default Footer;