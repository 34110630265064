import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';


import {
    Container,
    Row,
    Col,

} from 'reactstrap';


import Form from '../../components/forms/changePasswordForm';

import downArrow from '../../assets/svg/down-arrow.svg';
import facebook from '../../assets/svg/login-facebook.svg';
import twitter from '../../assets/svg/login-twitter.svg';
import google from '../../assets/svg/login-google.svg';


import bg from '../../assets/images/bg.jpg';
import {baseUrl} from "../../helpers/constants";


class ChangePasswordPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {

        };



    }




    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.props.updateMeta(this.props.generateSeoTags(null));

    }
    submit(data) {
        fetch(`${baseUrl}/reset-password/${this.props[0].match.params.uid}/${this.props[0].match.params.resetPasswordVerificationCode}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                localStorage.setItem('authToken', result.token);
                localStorage.removeItem('cart');
                this.props.verifyUser();
                this.props[0].history.push('/')
            }
        })
    }


    render() {

        return (
            <div className="login-wrap">
                <div className="into-wrap">
                    <div className="background"><img src={bg} /></div>
                    <Container>
                        <Row>
                            <Col lg="8">
                                <h1>Dont't Worry</h1>
                                <h2>You can always login with social media</h2>
                                <button className="scroll-down">
                                    <Isvg src={downArrow} />
                                </button>
                                <div className="social-buttons">
                                    <button className="facebook"><Isvg src={facebook} />Login with Facebook</button>
                                    <button className="twitter"><Isvg src={twitter} />Login with Twitter</button>
                                    <button className="google"><Isvg src={google} />Login with Google</button>

                                </div>
                            </Col>
                            <Col lg={{ size: 4 }}>
                                <div className="form-box reset-password-box">
                                    <div className="form-wrap">
                                        <h6>Reset Password</h6>
                                        <p>Enter your new password</p>

                                        <Form onSubmit={this.submit} />
                                        {this.state.error ? <p className="error">{this.state.error}</p> : null}

                                    </div>
                                    <div className="spacer"></div>
                                    <p className="bottom-link">Already have an account?  <Link to='/login'>Log in</Link></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <p className="copyright">© 2020 1dollar.solutions All rights reserved. Made with love for a better web.</p>

                </div>



            </div>
        );
    }
}

export default Page(ChangePasswordPage);
