import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Isvg from 'react-inlinesvg';


import star from '../../assets/svg/star.svg';
import fullStar from '../../assets/svg/full-star.svg';
import cart from '../../assets/svg/cart.svg';
import x from '../../assets/svg/x.svg';
import Image from '../image';

class CartArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }




    render() {

        return (
            <article className={this.props.listView ? 'list-article' : null}>
                <Image src={this.props.images && this.props.images[0]} />
                <div className="wrap">
                    <div>
                        <h6>{this.props.name}</h6>
                        <p>{this.props.shortDescription}</p>
                        <div className="quantity">
                            <button onClick={() => {
                                if (this.props.quantity - 1 > 0) {
                                    this.props.updateQuantity(this.props.quantity - 1);
                                }
                            }}>-</button>
                            <input type="text" value={this.props.quantity}></input>
                            <button onClick={() => {
                                if (this.props.quantity + 1 <= 10) {
                                    this.props.updateQuantity(this.props.quantity + 1);
                                }
                            }}>+</button>
                        </div>
                    </div>
                    <div className="bottom-wrap">
                        <button onClick={this.props.handleRemove}><Isvg src={x} /></button>
                        <span className="price">{ (this.props.price * this.props.quantity).formatPrice(2)} KM</span>
                    </div>
                </div>
            </article>
        );
    }
}

export default CartArticle;