
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import trashIcon from '../../../assets/svg/orders-trash.svg';
import Isvg from 'react-inlinesvg';

import Select from './selectList';
import {
    Col,
    Row,
    Container
} from 'reactstrap';

class Variations extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="input-wrap variations-wrap">
                {this.props.label ? <label>{this.props.label}                     <button onClick={() => {
                    let value = this.props.value;

                    if (!value) {
                        value = [];
                    }
                    value.push({
                        price: 1,
                        stock: 1,
                        name: ''
                    })

                    this.props.onChange(value);
                    this.forceUpdate();

                }} className="add-new" type="button">Dodaj</button>
                </label> : null}

                <Row>
                    <div className="table">
                        <div>
                            <table>
                                <tr>
                                    <th>Naziv</th>
                                    <th>Cijena</th>
                                    <th>Stanje</th>
                                    <th>Akcije</th>
                                </tr>
                                {
                                    this.props.value ? this.props.value.map((item, idx) => {
                                        if (item && !item.deleted)
                                            return (
                                                <tr>
                                                    <td><input type="text" value={item.name} onChange={(e) => {
                                                        let value = this.props.value;
                                                        value[idx].name = e.target.value;
                                                        this.props.onChange(value);
                                                        this.forceUpdate();
                                                    }} /></td>
                                                    <td><input type="text" value={item.price} onChange={(e) => {
                                                        let value = this.props.value;
                                                        value[idx].price = e.target.value;
                                                        this.props.onChange(value);
                                                        this.forceUpdate();
                                                    }} /></td>
                                                    <td><input type="number" value={item.stock} onChange={(e) => {
                                                        let value = this.props.value;
                                                        value[idx].stock = e.target.value;
                                                        this.props.onChange(value);
                                                        this.forceUpdate();
                                                    }} /></td>
                                                    <td><button onClick={() => {
                                                        let value = this.props.value;
                                                        value[idx].deleted = true;
                                                        this.props.onChange(value);
                                                        this.forceUpdate();

                                                    }} type="button"><Isvg src={trashIcon} /></button></td>
                                                </tr>

                                            )
                                    })

                                        :
                                        null
                                }

                            </table>
                        </div>
                    </div>
                </Row>

            </div>
        );
    }
}

export default Variations;