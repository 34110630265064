import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Isvg from 'react-inlinesvg';

import Image from '../image';

import star from '../../assets/svg/star.svg';
import fullStar from '../../assets/svg/full-star.svg';
import cart from '../../assets/svg/cart.svg';
import moment from 'moment';

class Article extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }




    render() {

        return (
            <article className={this.props.listView ? 'list-article' : null}>
                <Link to={`/artikal/${this.props.storeAlias}/${this.props.alias}/${this.props.sku}`}><Image src={this.props.images && this.props.images[0]} /></Link>
                <div className="wrap">
                    <div>
                        <Link to={`/artikal/${this.props.storeAlias}/${this.props.alias}/${this.props.sku}`}><h6>{this.props.name.length > (this.props.maxNameLength ? this.props.maxNameLength : 30) ? this.props.name.substring(0, this.props.maxNameLength ? this.props.maxNameLength : 30) + '...' : this.props.name}</h6></Link>
                        {this.props.listView ?
                        
                        <>
                        <p>{this.props.shortDescription}</p>
                        <p className="date">Prije {moment.duration(-(Math.floor(new Date().getTime() / 1000) - this.props.published), 'seconds').humanize()}  </p>

                        </>
                         : null }
                        
                        {/*<div className="rating">
                            <div className="stars">

                                <Isvg src={this.props.rating >= 0.5 ? fullStar : star} />
                                <Isvg src={this.props.rating >= 1.5 ? fullStar : star} />
                                <Isvg src={this.props.rating >= 2.5 ? fullStar : star} />
                                <Isvg src={this.props.rating >= 3.5 ? fullStar : star} />
                                <Isvg src={this.props.rating >= 4.5 ? fullStar : star} />

                            </div>
                            <span className="sales">{this.props.sales} Sales</span>
        </div>*/}

                    </div>
                    <div className="bottom-wrap">
                        <span className="price">{this.props.price ? this.props.price.formatPrice(0) : ''} KM</span>
                        <div className="buttons">
                           {/* <Link to={`/solution/${this.props.alias}`}><button className="preview-button">Preview</button></Link>*/}
                            <button className="cart-button " onClick={() => this.props.addToCart(this.props)}><Isvg src={cart} /></button>

                        </div>
                    </div>
                </div>
            </article>
        );
    }
}

export default Article;