import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

import Text from './fields/textIconError';
import Textarea from './fields/textarea';

import Check from './fields/check';
import Select from './fields/select';

import mail from '../../assets/svg/mail.svg';
import lock from '../../assets/svg/lock.svg';
import user from '../../assets/svg/user-icon.svg';

import rightChevron from '../../assets/svg/right-arrow.svg';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';



const required = value => (value || typeof value === 'number' ? undefined : 'Obavezno polje')
export const minLength = min => value =>
  value && value.length < min ? `Minimalno ${min} karaktera` : undefined
export const minLength5 = minLength(5)
export const minLength7 = minLength(7)
export const minLength9 = minLength(9)

const number = value =>
  value && isNaN(Number(value)) ? 'Mora biti broj' : undefined


const renderTextField = ({
    input,
    placeholder,
    label,
    icon,
    meta: { touched, error },
    type
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            icon={icon}
            errorText={touched && error}
            error={touched && error}
            type={type}
            {...input}
        />
    )

    const renderTextareaField = ({
        input,
        placeholder,
        label,
        icon,
        meta: { touched, error },
        type
    }) => (
    
            <Textarea
                placeholder={placeholder}
                label={label}
                icon={icon}
                errorText={touched && error}
                error={touched && error}
                type={type}
                {...input}
            />
        )
    

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        >{children}</Select>
    )

const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Check
            label={label}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )



class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit} className="edit-account-form">
                <Row>
                    <Col lg="12">
                        <h6>Podaci o dostavi</h6>
                    </Col>
                    <Col lg="6">
                        <Field
                            name="name"
                            component={renderTextField}
                            label="Ime i prezime"
                            validate={[required, minLength5]}
                        ></Field>
                    </Col>
                    <Col lg="6">
                        <Field
                            name="phoneNumber"
                            component={renderTextField}
                            label="Telefon"
                            validate={[required, minLength9]}

                        ></Field>

                    </Col>
                    <Col lg="6">
                        <Field
                            name="email"
                            component={renderTextField}
                            label="E-mail"
                            validate={[required, minLength7]}

                        ></Field>

                    </Col>

                    <Col lg="6">
                        <Field
                            name="address"
                            component={renderTextField}
                            label="Adresa"
                            validate={[required, minLength5]}

                        ></Field>

                    </Col>
                    <Col lg="6">
                        <Field
                            name="city"
                            component={renderTextField}
                            label="Grad"
                            validate={[required, minLength5]}
                        ></Field>

                    </Col>
                    <Col lg="6">
                        <Field
                            name="postNumber"
                            component={renderTextField}
                            label="Poštanski broj"
                            validate={[required, number, minLength5]}
                        ></Field>

                    </Col>

                    <Col lg="12">
                        <Field
                            name="note"
                            component={renderTextareaField}
                            label="Napomena"
                        ></Field>

                    </Col>

                    <Col lg="12">
                    <Field
                            name="check"
                            component={renderCheckField}
                            label={<>Prihvatam <Link to='/page/uslovi-poslovanja'>Uslove poslovanja</Link> Shop-a</>}
                            validate={[required]}
                        ></Field>
                        </Col>
                        
                    <Col lg="12">
                            <button className="submit-button">Završi narudžbu <Isvg src={rightChevron} /> </button>
                        </Col>


                </Row>
            </form>
        )
    }
}

export default reduxForm({
    form: 'addressForm'  // a unique identifier for this form
})(form)
