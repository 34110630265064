import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import Form from '../components/forms/contactForm';

import rightArrow from '../assets/svg/right-arrow.svg';

import bg from '../assets/images/category-bg.jpg';

import doneIcon from '../assets/svg/done.svg';
import {baseUrl} from "../helpers/constants";



class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);

        this.state = {
            ...props.initialData
        };
    }

    init() {
        window.scrollTo(0, 0);

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }

    }

    componentDidMount() {
        this.init()

    }

    componentDidUpdate(prevProps) {

        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.init();
        }
    }

    contact = (data) => {
        fetch(`${baseUrl}/contact/store-request/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            if (!result.error) {
                this.setState({
                    _contactDone: true
                })
            }
        })
    }


    render() {

        return (
            <div className="contact-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h1>Otvorite svoj Shop</h1>
                                <p>Kontaktirajte nas</p>
                            </Col>
                        </Row>

                    </Container>
                </div>

                <section className="section-dynamic">
                    <Container>
                        <Row>
                            <Col lg={{ size: 8, offset: 2 }} className="form-wrapper">
                                {!this.state._contactDone ?
                                    <Form onSubmit={this.contact} />
                                    :

                                    <div className="no-items">
                                        <Isvg src={doneIcon} />
                                        <h6>Vaša zahtjev je poslat!</h6>
                                        <p>Očekujte odgovor ubrzo.</p>
                                    </div>
                                }

                            </Col>
                        </Row>
                    </Container>
                </section>


            </div>
        );
    }
}

export default Page(ContactPage);
