import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import {
    Container,
    Row,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    CarouselControl,
    Carousel,
    CarouselItem

} from 'reactstrap';

import Image from '../components/image';

import BlogArticle from '../components/articles/blogArticle';
import CommentForm from '../components/forms/commentForm';
import ReplyForm from '../components/forms/replyForm';

import ReviewForm from '../components/forms/reviewForm';

import rightArrow from '../assets/svg/right-arrow.svg';
import cart from '../assets/svg/cart.svg';
import star from '../assets/svg/star.svg';
import fullStar from '../assets/svg/full-star.svg';
import comment from '../assets/svg/comment.svg';


import storeIcon from '../assets/svg/store.svg';


import moment from 'moment';


import Slider from "react-slick";
import Select from '../components/forms/fields/select';
import {baseUrl} from "../helpers/constants";

class DetailPage extends Component {
    constructor(props) {
        super(props);
        this.comment = this.comment.bind(this);
        this.rate = this.rate.bind(this);
        this._renderComment = this._renderComment.bind(this);
        this.init = this.init.bind(this);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);


        this.state = {
            ...props.initialData,
            activeIndex: 0
        };
    }



    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.product.images.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.product.images.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    init() {
        fetch(`${baseUrl}/products/track/${this.props[0].match.params.storeAlias}/${this.props[0].match.params.alias}/${this.props[0].match.params.sku}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        });

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }

    }

    componentDidMount() {

        window.scrollTo(0, 0);
        this.init()

    }

    componentDidUpdate(prevProps) {

        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.init();
        }
    }

    comment(data, parent = null) {
        fetch(`${baseUrl}/products/comment/${this.props[0].match.params.storeAlias}/${this.props[0].match.params.alias}/${this.props[0].match.params.sku}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({
                comment: data.comment,
                parent: parent
            })
        }).then(res => res.json()).then((result) => {
            this.setState({
                parentComment: null
            })
            this.init();
        })


    }

    rate(data) {
        fetch(`${baseUrl}/products/rate/${this.props[0].match.params.storeAlias}/${this.props[0].match.params.alias}/${this.props[0].match.params.sku}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({
                comment: data.comment,
                rating: data.rating
            })
        }).then(res => res.json()).then((result) => {
            this.init();
        })


    }


    _renderComment(item) {
        return (

            <ul>
                <li>
                    <div>
                        <span className="user">{item.user}</span>
                        <span>{moment.duration(-(new Date().getTime() / 1000 - item.timestamp), 'seconds').humanize()} ago</span>
                    </div>
                    <div className="content">
                        <p>{item.comment}</p>
                        {this.props.uData && this.props.uData._id != item.uid && this.state.parentComment != item._id ? <button className="reply-button" onClick={() => this.setState({
                            parentComment: item._id
                        })}>Reply</button> : null}
                        {
                            this.state.parentComment == item._id ?
                                <ReplyForm onSubmit={(data) => this.comment(data, item._id)} />
                                :

                                null
                        }
                    </div>
                    {
                        item.replies && item.replies.length ?
                            item.replies.map((reply) => {
                                return this._renderComment(reply)
                            })
                            :
                            null
                    }
                </li>
            </ul>
        )

    }

    render() {
        let product = this.state.product ? this.state.product : { images: [], notLoaded: true };

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,

        };

        const settings1 = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: typeof window !== 'undefined' && window.innerWidth < 768 ? 2 : 5,
            slidesToScroll: 1,

        };


        const { activeIndex } = this.state;

        const slides = product && product.images ? product.images.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item}
                >
                    <img src={item} />
                </CarouselItem>
            );
        }) : null;


        let variation;
        if (this.state.variation && product.variations) {
            for (let i = 0; i < product.variations.length; i++) {
                if (product.variations[i]._id == this.state.variation) {
                    variation = product.variations[i];
                    break;
                }
            }
        }


        return (
            <div className="detail-wrap">
                {
                    product && !product.notLoaded && !product.name && !product.published ?
                        <Redirect to="/"></Redirect>
                    :
                    null
                }
                <div className="top-into-wrap">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>{product.name}</h1>
                                <ul className="bcrumb">
                                    <li><Link to='/'> Početna   </Link></li>
                                    <li><Link to='/kategorije'> Sve kategorije     </Link></li>
                                    {
                                        this.state.category && this.state.category.breadcrumbItems && this.state.category.breadcrumbItems.map((item, idx) => {
                                            return (
                                                <li><Link to={`/kategorije${item.breadcrumb}`}> {item.name} </Link></li>

                                            )
                                        })
                                    }

                                </ul>

                            </Col>
                            <Col lg={{ size: 6 }}>
                                <div className="search-wrap">
                                    <input type="text" placeholder="Unesite pojam za pretragu" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} onKeyUp={(e) => {
                                        if (e.keyCode == 13) {
                                            e.preventDefault();
                                            this.props[0].history.push(`/kategorije${this.state.search ? `?search=${encodeURIComponent(this.state.search)}` : ''}`)
                                        }
                                    }} />
                                    <button className="button" onClick={() => {
                                        this.props[0].history.push(`/kategorije${this.state.search ? `?search=${encodeURIComponent(this.state.search)}` : ''}`);

                                    }}>TRAŽI <Isvg src={rightArrow} /> </button>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>

                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col xl="6" lg="4" className="image-wrap">
                                <div>
                                    <div className="images">
                                        <Slider {...settings}>
                                            {
                                                product.images && product.images.map((item, idx) => {
                                                    return (
                                                        <div className="image" onClick={() => this.setState({ previewImage: item, activeIndex: idx })}>
                                                            <img src={item} />
                                                        </div>
                                                    )
                                                })
                                            }

                                        </Slider>

                                    </div>
                                    <div className="preview" onClick={() => this.setState({ _modal: true })}>
                                        <img src={this.state.previewImage ? this.state.previewImage : product && product.images && product.images[0]} />

                                    </div>
                                </div>
                            </Col>
                            <Col xl="4" lg="5" className="article-info">
                                <div className="table">
                                    <div className="item">
                                        <h6>Cijena</h6>
                                        {variation ?
                                            <p>{variation.price ? variation.price.formatPrice(2) : (0).formatPrice(2)} KM</p>

                                            :
                                            <p>{product.price ? product.price.formatPrice(2) : (0).formatPrice(2)} KM</p>

                                        }
                                    </div>
                                    <div className="item">
                                        <h6>Količina</h6>
                                        {variation ?
                                            <p>{variation.stock}</p>

                                            :
                                            <p>{product.stock}</p>
                                        }
                                    </div>
                                    <div className="item">
                                        <h6>Stanje</h6>
                                        <p>{product.used ? 'Korišteno' : 'Novo'}</p>
                                    </div>

                                </div>

                                <Row>
                                    <Col lg="4" xs="6" className="article-info-item">
                                        <h6>LOKACIJA</h6>
                                        <p>{product.city}</p>
                                    </Col>
                                    <Col lg="4" xs="6" className="article-info-item">
                                        <h6>OBJAVLJENO</h6>
                                        <p>{moment.unix(product.published).format('DD.MM.YYYY')}</p>
                                    </Col>

                                    {product.variations ?
                                        <Col lg="12">
                                            <div className="detail-variations">
                                                <Select value={this.state.variation} onChange={(val) => this.setState({
                                                    variation: val
                                                })} label={product.variationName}>
                                                    {
                                                        product.variations.map((item) => {
                                                            return (
                                                                <option value={item._id}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </Col>
                                        :
                                        null
                                    }
                                    <Col lg="12" className="article-info-description">
                                        <h6>KRATKI OPIS</h6>
                                        <p dangerouslySetInnerHTML={{ __html: product.shortDescription ? product.shortDescription.replace(/\n/g, '<br/>') : '' }}></p>
                                    </Col>
                                    <Col lg="12">
                                        <div className="shop-buttons">
                                            <button className="add-to-cart" onClick={() => {
                                                if (variation) {
                                                    this.props.addToCart(variation);
                                                } else {
                                                    this.props.addToCart(product);
                                                }

                                            }}><Isvg src={cart} /> DODAJ U KORPU</button>
                                            <button className="buy-now" onClick={() => {
                                                if (variation) {
                                                    this.props.addToCart(variation);
                                                } else {
                                                    this.props.addToCart(product);
                                                }
                                                this.props[0].history.push('/cart');
                                            }}> KUPI ODMAH </button>

                                        </div>

                                    </Col>
                                </Row>

                            </Col>
                            <Col xl="2" lg="3" className="account-info store-info">
                                <div className={this.state.showStoreInfo ? 'open' : ''}>
                                    <div className="mobile-trigger"><button onClick={() => this.setState({ showStoreInfo: !this.state.showStoreInfo })}><Isvg src={storeIcon} /></button></div>
                                    <div>
                                        <Link to={`/shop/${this.props[0].match.params.storeAlias}`}>
                                            <div className="profile-image">
                                                <img src={product.storeImage} />
                                            </div>
                                            <h6>{product.storeName}</h6>
                                        </Link>
                                        <p>PREMIUM SHOP</p>
                                    </div>
                                    <div>
                                        <Link to={`/shop/${this.props[0].match.params.storeAlias}/kontakt`}>
                                            <button>POŠALJI PORUKU</button>
                                        </Link>
                                        <Link to={`/shop/${this.props[0].match.params.storeAlias}`}>
                                            <button>POGLEDAJ SHOP</button>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>

                </div>



                <Container className="product-detail-wrap">

                    <Row>
                        <Col lg="10">
                            <ul className="tabs">
                                <li className={!this.props[0].match.params.tab ? 'active' : null}><Link to={`/artikal/${this.props[0].match.params.storeAlias}/${this.props[0].match.params.alias}/${this.props[0].match.params.sku}`}>Detaljne informacije  </Link> </li>
                                <li className={this.props[0].match.params.tab == 'reviews' ? 'active' : null}><Link to={`/artikal/${this.props[0].match.params.storeAlias}/${this.props[0].match.params.alias}/${this.props[0].match.params.sku}/reviews`}> Ocjene  </Link> </li>
                                <li className={this.props[0].match.params.tab == 'comments' ? 'active' : null}><Link to={`/artikal/${this.props[0].match.params.storeAlias}/${this.props[0].match.params.alias}/${this.props[0].match.params.sku}/comments`}> Komentari  </Link> </li>

                            </ul>
                            <div className="tab-container">
                                {this.props[0].match.params.tab == 'comments' ?
                                    <div className="comments">

                                        {this.state.comments && this.state.comments.length ?
                                            <div className="comments-top">
                                                <h6>{this.state.comments && this.state.comments.length} Komentara</h6>

                                            </div>

                                            :
                                            <div className="no-comments">
                                                <Isvg src={comment} />
                                                <h6>Ovaj artikal nema komentara</h6>
                                                <p>Budite prvi koji će ostaviti komentar.</p>
                                            </div>

                                        }

                                        {
                                            this.state.comments && this.state.comments.map((item, idx) => {
                                                return (
                                                    <div className="comment-wrap" key={idx}>
                                                        {this._renderComment(item)}
                                                    </div>
                                                )
                                            })
                                        }


                                        <CommentForm onSubmit={this.comment}></CommentForm>

                                    </div>
                                    :
                                    null
                                }
                                {this.props[0].match.params.tab == 'reviews' ?
                                    <div className="reviews">
                                        {
                                            this.state.reviews && this.state.reviews.length ?
                                                <>
                                                    <div className="reviews-top">
                                                        <h6>{this.state.reviews && this.state.reviews.length} Ocjena</h6>
                                                        <div className="sort">
                                                            <span>Sortiranje:</span>
                                                            <ul>
                                                                <li className="active"><Link to='/'><button>Najnovije</button> </Link></li>
                                                                <li ><Link to='/'><button>Najbolje ocjenjeno</button> </Link></li>
                                                                <li ><Link to='/'><button>Najlošije ocjenjeno</button> </Link></li>

                                                            </ul>
                                                        </div>

                                                        <div className="sort-wrap">
                                                            <span>Sort:</span>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle caret>
                                                                    Newest
                                        </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem>Newest</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                        </div>

                                                    </div>
                                                    {
                                                        this.state.reviews.map((item, idx) => {
                                                            return (
                                                                <div className="review">
                                                                    <div>
                                                                        <div>
                                                                            <Isvg src={item.rating >= 0.5 ? fullStar : star} />
                                                                            <Isvg src={item.rating >= 1.5 ? fullStar : star} />
                                                                            <Isvg src={item.rating >= 2.5 ? fullStar : star} />
                                                                            <Isvg src={item.rating >= 3.5 ? fullStar : star} />
                                                                            <Isvg src={item.rating >= 4.5 ? fullStar : star} />
                                                                            <span>by <span>{item.user}</span></span>
                                                                        </div>
                                                                        <span>{moment.duration(-(Math.floor(new Date().getTime() / 1000) - item.timestamp), 'seconds').humanize()} ago</span>

                                                                    </div>
                                                                    {item.comment ? <p>{item.comment}</p> : null}
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </>
                                                :

                                                <div className="no-reviews">
                                                    <Isvg src={star} />
                                                    <h6>Ovaj artikal nema ocjena.</h6>
                                                    <p>Budite prvi koji će ocjeniti ovaj artikal.</p>
                                                </div>

                                        }


                                        {
                                            this.state.ratingAllowed ?
                                                this.state.review ?
                                                    <ReviewForm initialValues={this.state.review} onSubmit={this.rate} />
                                                    :
                                                    <ReviewForm onSubmit={this.rate} />
                                                :
                                                null
                                        }


                                    </div>

                                    :
                                    null
                                }

                                {!this.props[0].match.params.tab ?
                                    <div className="article-desc">
                                        <div className="article-description" dangerouslySetInnerHTML={{ __html: product.description }}>
                                        </div>
                                        <table >
                                            <tr>
                                                <td>Dužina</td>
                                                <td>{product.length}cm</td>

                                            </tr>
                                            <tr>
                                                <td>Širina</td>
                                                <td>{product.width}cm</td>

                                            </tr>
                                            <tr>
                                                <td>Visina</td>
                                                <td>{product.height}cm</td>

                                            </tr>

                                        </table>
                                    </div>

                                    :
                                    null
                                }


                            </div>
                        </Col>
                        <Col lg="2" className="banner-area">
                            <div>
                                <a href='https://popusti.euroexpress.ba' target='_blank'>
                                    <img src="https://api.eeshop.ba/uploads/popusti.png" />
                                </a>
                            </div>
                        </Col>

                    </Row>

                </Container>

                {this.state._modal ?
                    <div className="lightbox">
                        <button className="close-button" onClick={() => this.setState({ _modal: null })}></button>
                        <Container>
                            <Row>
                                <Col lg={{ size: 8, offset: 2 }}>
                                    <Carousel
                                        activeIndex={activeIndex}
                                        next={this.next}
                                        previous={this.previous}
                                    >
                                        {slides}
                                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />

                                    </Carousel>
                                    {product.images && product.images.length >= 5 ?
                                        <div className="thumbs">
                                            <Slider {...settings1}>
                                                {
                                                    product.images && product.images.map((item, idx) => {
                                                        return (
                                                            <div className="image" onClick={() => this.setState({ previewImage: item, activeIndex: idx })}>
                                                                <img src={item} />
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </Slider>

                                        </div>
                                        :
                                        null
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    : null
                }



            </div>
        );
    }
}

export default Page(DetailPage);
