import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Isvg from 'react-inlinesvg';

import Image from '../image';

import star from '../../assets/svg/star.svg';
import fullStar from '../../assets/svg/full-star.svg';
import cart from '../../assets/svg/cart.svg';

import moment from 'moment';
moment.locale('sr');

class DownloadArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }




    render() {

        return (
            <article className={this.props.listView ? 'list-article' : null}>
                <Image src={this.props.product.images && this.props.product.images[0]} />
                <div className="wrap">
                    <div className="product-desc">
                        <h6>{this.props.product.name}</h6>
                        <p>{this.props.product.shortDescription}</p>
                        <p className="date">Prije {moment.duration(-(Math.floor(new Date().getTime() / 1000) - this.props.timestamp), 'seconds').humanize()}  </p>
                    </div>
                    <div className="bottom-wrap">
                        <span className="price">{this.props.product.quantity} x { (this.props.product.price).formatPrice(2)} KM</span>

                        <div className="buttons">
                            <Link to={`/artikal/${this.props.product.storeAlias}/${this.props.product.alias}/${this.props.product.sku}/reviews`}><button className="preview-button">Ocjeni</button></Link>
                        </div>

                    </div>
                </div>
            </article>
        );
    }
}

export default DownloadArticle;