import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';


import {
    Container,
    Row,
    Col,

} from 'reactstrap';


import Form from '../../components/forms/editAccountForm';
import BlogArticle from '../../components/articles/blogArticle';


import rightArrow from '../../assets/svg/right-arrow.svg';
import user from '../../assets/svg/user.svg';
import {baseUrl} from "../../helpers/constants";


class EditAccountPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        window.scrollTo(0, 0);


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }
    }

    submit(data) {
        console.log(data);
        fetch(`${baseUrl}/user/edit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                this.props.verifyUser();
                this.props[0].history.push('/')
            }
        })

    }

    render() {
        return (
            <div className="account-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="4" className="user-info">
                                <Isvg src={user} />
                                <div>
                                    <h1>Moj nalog</h1>
                                    <p className="email">{this.props.uData && this.props.uData.email}</p>
                                    <button onClick={() => this.props.signOut()}>Izloguj se</button>
                                </div>
                            </Col>
                            <Col lg={{ size: 8 }} className="user-nav">
                                <Link to='/account/orders'><button >MOJE NARUDŽBE</button></Link>
                                {
                                    this.props.uData && this.props.uData.storeAlias ?
                                        <Link to={`/shop/${this.props.uData.storeAlias}`}><button>MOJ SHOP</button></Link>

                                        :
                                        null
                                }

                                <Link to='/account/edit'><button className="active">PODEŠAVANJA</button></Link>
                                <Link to='/account/reviews'><button >OCJENE</button></Link>
                                {this.props.uData && this.props.uData.permissions && this.props.uData.permissions.indexOf('*') !== -1 ? <Link to='/account/categories'><button >ADMINISTRACIJA</button></Link> : null}

                            </Col>
                        </Row>

                    </Container>
                </div>


                <section className="edit-account-section">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>Podešavanja</h2>
                                <Form initialValues={this.props.uData} onSubmit={this.submit} />
                                {this.state.error ? <p className="error">{this.state.error}</p> : null}

                            </Col>
                        </Row>

                    </Container>

                </section>



            </div>
        );
    }
}

export default Page(EditAccountPage);
