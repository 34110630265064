import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import BlogArticle from '../components/articles/blogArticle';

import rightArrow from '../assets/svg/right-arrow.svg';

import bg from '../assets/images/category-bg.jpg';




class DynamicPage extends Component {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);

        this.state = {
            ...props.initialData
        };
    }

    init() {
        window.scrollTo(0, 0);

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }

    }

    componentDidMount() {
        this.init()

    }

    componentDidUpdate(prevProps) {

        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.init();
        }
    }



    render() {

        return (
            <div className="contact-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h1>{this.state.data && this.state.data.name}</h1>
                                <p>{this.state.data && this.state.data.subtitle}</p>
                            </Col>
                        </Row>

                    </Container>
                </div>

                <section className="section-dynamic">
                    <Container>
                        <Row>
                            <Col lg="12" dangerouslySetInnerHTML={{__html: this.state.data && this.state.data.content}}>
                                </Col>
                        </Row>
                    </Container>
                </section>


            </div>
        );
    }
}

export default Page(DynamicPage);