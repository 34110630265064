import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import BlogArticle from '../../components/articles/blogArticle';
import rightArrow from '../../assets/svg/right-arrow.svg';
import user from '../../assets/svg/user.svg';
import penIcon from '../../assets/svg/orders-pen.svg';
import trashIcon from '../../assets/svg/orders-trash.svg';

import ReactPaginate from 'react-paginate';
import moment from 'moment';
import {baseUrl} from "../../helpers/constants";

class UsersPage extends Component {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);
        this.getSearchParams = this.getSearchParams.bind(this);
        this.generateSearchLink = this.generateSearchLink.bind(this);

        this.state = {
            ...props.initialData,
            products: [],
            testPageNumber: 0 //for pagination on browser back button, used in forcePage pagination attribute
        };
    }
    getSearchParams() {
        let brokenParams = this.props[0].location.search.replace('?', '').split('&');
        let params = {};
        for (let i = 0; i < brokenParams.length; i++) {
            params[brokenParams[i].split('=')[0]] = brokenParams[i].split('=')[1];
        }

        return params;
    }

    generateSearchLink(name, value, isValueArray) {
        let params = this.getSearchParams();

        if (!value) {
            delete params[name];
        } else {
            if (isValueArray) {
                if (!params[name]) {
                    params[name] = [];
                }


                if (params[name].indexOf(value) !== -1) {
                    params[name].splice(params[name].indexOf(value), 1);
                } else {
                    params[name].push(value);
                }
                params[name] = params[name].join(',');
            } else {
                params[name] = value;
            }
        }


        let paramsGroup = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                paramsGroup.push(`${key}=${params[key]}`)
            }
        }


        return `?${paramsGroup.join('&')}`;
    }

    init() {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname, this.getSearchParams()).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }




    }

    componentDidMount() {

        window.scrollTo(0, 0);
        this.init()

    }

    componentDidUpdate(prevProps) {

        if (prevProps[0].location.pathname != this.props[0].location.pathname || prevProps[0].location.search != this.props[0].location.search) {
            this.init();
            this.setState({
                testPageNumber: this.getSearchParams().page? Number(this.getSearchParams().page) : 0
            })
        }
    }



    render() {

        return (
            <div className="account-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="4" className="user-info">
                                <Isvg src={user} />
                                <div>
                                    <h1>Moj nalog</h1>
                                    <p className="email">{this.props.uData && this.props.uData.email}</p>
                                    <button onClick={() => this.props.signOut()}>Izloguj se</button>
                                </div>
                            </Col>
                            <Col lg={{ size: 8 }} className="user-nav">
                                <Link to='/account/orders'><button >MOJE NARUDŽBE</button></Link>
                                <Link to='/account/edit'><button>PODEŠAVANJA</button></Link>
                                <Link to='/account/reviews'><button >OCJENE</button></Link>
                                {this.props.uData && this.props.uData.permissions && this.props.uData.permissions.indexOf('*') !== -1 ? <Link to='/account/categories'><button className="active">ADMINISTRACIJA</button></Link> : null}

                            </Col>
                        </Row>

                    </Container>
                </div>


                <section >
                    <Container>
                        <Row>
                            <Col lg="12" >
                                <div className="admin-form">
                                    <ul className="tabs">
                                        <li >
                                            <Link to='/account/categories'>Kategorije</Link>
                                        </li>
                                        <li className="active">
                                            <Link to='/account/users'>Korisnici</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/stores'>Radnje</Link>
                                        </li>

                                        <li >
                                            <Link to='/account/news'>Novosti</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/pages'>Stranice</Link>
                                        </li>

                                        <li>
                                            <Link to='/'>Statistike</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/create-store'>OTVORITE SHOP</Link>
                                        </li>
                                    </ul>

                                    <div className="form-wrapper">
                                        <div className="top">
                                            <h3>Korisnici</h3>

                                        </div>
                                        <div className="table">
                                            <div>
                                                <table>
                                                    <tr>
                                                        <th>E-mail</th>
                                                        <th>Datum registracije</th>
                                                        <th>Akcije</th>
                                                    </tr>

                                                    {
                                                        this.state.items && this.state.items.length && this.state.items.map((item, idx) => {
                                                            return (
                                                                <tr>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.registerTimestamp ? moment.unix(item.registerTimestamp).format('DD.MM.YYYY. | HH:mm') + ' h' : null}</td>

                                                                    <td>
                                                                        <Link to={`/account/users/${item._id}`}><button><Isvg src={penIcon} /></button></Link>
                                                                        <button onClick={() => this.props.handleDelete(() => {
                                                                            fetch(`${baseUrl}/users/delete/${item._id}`, {
                                                                                method: 'DELETE',
                                                                                headers: {
                                                                                    'content-type': 'application/json',
                                                                                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`

                                                                                },
                                                                            }).then(res => res.json()).then((result) => {

                                                                                this.init();
                                                                            })

                                                                        })}><Isvg src={trashIcon} /></button>
                                                                    </td>

                                                                </tr>

                                                            )
                                                        })
                                                    }
                                                </table>
                                            </div>

                                            <ReactPaginate
                                                forcePage={this.state.testPageNumber}
                                                previousLabel={''}
                                                nextLabel={''}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={this.state.total}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={2}
                                                onPageChange={(page) => { this.props[0].history.push(this.generateSearchLink('page', page.selected)) }}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                                hrefBuilder={(page) => { return this.generateSearchLink('page', page) }}
                                            />




                                        </div>


                                    </div>
                                </div>

                            </Col>
                        </Row>

                    </Container>

                </section>




            </div>
        );
    }
}

export default Page(UsersPage);
