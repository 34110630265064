import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom'
import Isvg from 'react-inlinesvg';
import { connect } from 'react-redux'

import Text from './fields/textIcon';
import Check from './fields/check';
import Html from './fields/html';
import Gallery from './fields/gallery';
import Category from './fields/category';
import Image from './fields/image';
import Map from './fields/map';
import Select from './fields/select';

import rightChevron from '../../assets/svg/right-arrow.svg';
import regions from '../../regions';


import {
    Col,
    Row,
    Container
} from 'reactstrap';



const required = value => value ? undefined : "Required"




const renderMapField = ({
    input,
    placeholder,
    label,
    _googleMapsLoaded,
    meta: { touched, error },

}) => (

    <Map
        label={label}
        _googleMapsLoaded={_googleMapsLoaded}
        {...input}
    />
)

const renderTextField = ({
    input,
    placeholder,
    label,
    icon,
    type,
    meta: { touched, error },
    disabled
}) => (

    <Text
        placeholder={placeholder}
        label={label}
        icon={icon}
        errorText={touched && error}
        error={touched && error}
        type={type}
        disabled={disabled}
        {...input}
    />
)
const renderSelectField = ({
    input,
    placeholder,
    label,
    children,
    meta: { touched, error },
    disabled
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        disabled={disabled}

        {...input}
    >{children}</Select>
)



const renderCategoryField = ({
    input,
    label,
    categories,
    meta: { touched, error },
}) => (

    <Category
        categories={categories}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)



const renderHtmlField = ({
    input,
    placeholder,
    label,
    icon,
    type,
    height,
    meta: { touched, error },
}) => (

    <Html
        placeholder={placeholder}
        label={label}
        icon={icon}
        errorText={touched && error}
        error={touched && error}
        type={type}
        height={height}
        {...input}
    />
)

const renderGalleryField = ({
    input,
    label,
    height,
    meta: { touched, error },
}) => (

    <Gallery
        label={label}
        errorText={touched && error}
        error={touched && error}
        height={height}
        {...input}
    />
)
const renderImageField = ({
    input,
    label,
    height,
    meta: { touched, error },
}) => (

    <Image
        label={label}
        errorText={touched && error}
        error={touched && error}
        height={height}
        {...input}
    />
)



const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

    <Check
        label={label}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)



class SelectingFormValuesForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(this.props.region);

        let cities;

        if (this.props.region) {
            cities = [];
            for (let i = 0; i < regions.length; i++) {
                if (regions[i].name == this.props.region) {
                    cities = regions[i].cities;
                    break;
                }
            }
        }

        return (
            <form onSubmit={handleSubmit}>
                <Container>
                    <Row>


                        <Col lg="3">

                            <Field
                                name="referentniBroj"
                                component={renderTextField}
                                label="Referentni broj"
                                disabled={true}
                            >
                            </Field>

                        </Col>
{/*
                        <Col lg="3">

                            <Field
                                name="opisPosiljke"
                                component={renderTextField}
                                label="Opis posiljke"
                            >
                            </Field>

</Col>*/}
                        {/*<Col lg="3">

                            <Field
                                name="tezina"
                                component={renderTextField}
                                label="Tezina"
                            >
                            </Field>

        </Col>*/}
                        <Col lg="3">

                            <Field
                                name="brojPaketa"
                                component={renderTextField}
                                label="Broj paketa"
                                disabled={true}
                            >
                            </Field>

                        </Col>
                        <Col lg="3">

                            <Field
                                name="vrednostPosiljke"
                                component={renderTextField}
                                label="Vrednost posiljke"
                                disabled={true}
                            >
                            </Field>

                        </Col>
                        <Col lg="3">

                            <Field
                                name="iznosNaplatePouzecem"
                                component={renderTextField}
                                label="Iznos naplate"
                            >
                            </Field>

                        </Col>
                        <Col lg="12"></Col>

                        <Col lg="3">

                            <Field
                                name="tipNajave"
                                component={renderSelectField}
                                label="Tip najave"
                                disabled={true}

                            >
                                <option value={0}>Pošiljalac</option>
                                <option value={1}>Primalac</option>

                            </Field>

                        </Col>

                        <Col lg="3">

                            <Field
                                name="vrstaPosiljkeSifra"
                                component={renderSelectField}
                                label="Vrsta pošiljke"
                                disabled={true}

                            >
                                <option value={0}>Dokument</option>
                                <option value={1}>Paket</option>

                            </Field>

                        </Col>

                        <Col lg="3">

                            <Field
                                name="obveznikPlacanja"
                                component={renderSelectField}
                                label="Obveznik plaćanja"
                                disabled={true}

                            >
                                <option value={0}>Pošiljalac</option>
                                <option value={1}>Primalac</option>
                                <option value={2}>Treće lice</option>

                            </Field>

                        </Col>
                        <Col lg="3">

                            <Field
                                name="tipPlacanja"
                                component={renderSelectField}
                                label="Tip plaćanja"
                                disabled={true}

                            >
                                <option value={0}>Gotovina</option>
                                <option value={1}>Žirala</option>

                            </Field>

                        </Col>


                        <Col lg="3">

                            <Field
                                name="naplataPouzecem"
                                component={renderCheckField}
                                label="Naplata pouzećem"

                            ></Field>

                        </Col>
                        <Col lg="3">

                            <Field
                                name="povratOtpremnice"
                                component={renderCheckField}
                                label="Povrat otpremnice"

                            ></Field>

                        </Col>
                        <Col lg="3">

                            <Field
                                name="osiguranje"
                                component={renderCheckField}
                                label="Osiguranje"

                            ></Field>

                        </Col>
                        <Col lg="3">

                            <Field
                                name="otvaranjePosiljke"
                                component={renderCheckField}
                                label="Otvaranje pošiljke"

                            ></Field>

                        </Col>
                        <Col lg="3">

                            <Field
                                name="dostavaVikendom"
                                component={renderCheckField}
                                label="Dostava vikendom"

                            ></Field>

                        </Col>
                        <Col lg="3">

                            <Field
                                name="brzaDostava"
                                component={renderCheckField}
                                label="Brza dostava"

                            ></Field>

                        </Col>
                        <Col lg="3">

                            <Field
                                name="express"
                                component={renderCheckField}
                                label="Express"

                            ></Field>

                        </Col>





                        <Col lg="12" className="order-buttons">
                        <button onClick={() => this.props.close()} type="button" className="submit-button close-button">Zatvori  </button>

                            <button className="submit-button">Posalji najavu <Isvg src={rightChevron} /> </button>
                        </Col>


                    </Row>

                </Container>


            </form>
        )
    }
}

SelectingFormValuesForm = reduxForm({
    form: 'selectingFormValues' // a unique identifier for this form
})(SelectingFormValuesForm)

// Decorate with connect to read form values
const selector = formValueSelector('selectingFormValues') // <-- same as form name
SelectingFormValuesForm = connect(state => {
    const region = selector(state, 'region')
    return {
        region
    }
})(SelectingFormValuesForm)

export default SelectingFormValuesForm
