import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';


import {
    Container,
    Row,
    Col,

} from 'reactstrap';


import Form from '../../components/forms/registerForm';

import downArrow from '../../assets/svg/down-arrow.svg';
import facebook from '../../assets/svg/login-facebook.svg';
import twitter from '../../assets/svg/login-twitter.svg';
import google from '../../assets/svg/login-google.svg';


import bg from '../../assets/images/bg.jpg';
import emailSent from '../../assets/svg/email-sent.svg';
import {baseUrl} from "../../helpers/constants";


class RegisterPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {

        };



    }




    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.props.updateMeta(this.props.generateSeoTags(null));


    }


    submit(data) {
        fetch(`${baseUrl}/user/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                this.setState({
                    done: true
                })
            }
        })
    }

    render() {

        return (
            <div className="login-wrap">
                <div className="into-wrap">
                    <div className="background"><img src={bg} /></div>
                    <Container>
                        <Row>
                            <Col lg="8">
                                <h1>Kreirajte nalog</h1>
                                <h2>Za bržu registraciju prijavite se<br/>
pomoću društvenih mreža</h2>
                                <button className="scroll-down">
                                    <Isvg src={downArrow} />
                                </button>
                                <div className="social-buttons">
                                    <button className="facebook"><Isvg src={facebook} />Login with Facebook</button>
                                    <button className="twitter"><Isvg src={twitter} />Login with Twitter</button>
                                    <button className="google"><Isvg src={google} />Login with Google</button>

                                </div>
                            </Col>
                            <Col lg={{ size: 4 }}>
                                <div className="form-box">
                                    <div className="form-wrap">
                                        <h6>Register</h6>
                                        <p>Free access to our dashboard.</p>
                                        {!this.state.done ?
                                            <>
                                                <Form onSubmit={this.submit} />
                                                {this.state.error ? <p className="error">{this.state.error}</p> : null}
                                            </>
                                            :
                                            <div className="email-sent">
                                                <Isvg src={emailSent} />
                                                <h6>Poslali smo Vam E-mail</h6>
                                                <p>Primićete E-mail sa linkom za verifikaciju naloga.</p>
                                            </div>
                                        }
                                    </div>
                                    <div className="spacer"></div>
                                    <p className="bottom-link">Već imate nalog? <Link to='/login'>Ulogujte se</Link></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <p className="copyright">© 2020 eeshop.ba All rights reserved. Made with love for a better web.</p>

                </div>



            </div>
        );
    }
}

export default Page(RegisterPage);
