import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import {
    Container,
    Row,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';


import Article from '../components/articles/article';
import BlogArticle from '../components/articles/blogArticle';

import rightArrow from '../assets/svg/right-arrow.svg';
import downArrow from '../assets/svg/down-arrow.svg';

import grid from '../assets/svg/grid.svg';
import list from '../assets/svg/list.svg';
import filterIcon from '../assets/svg/filters.svg';

import SelectList from '../components/forms/fields/selectList';
import MultiselectList from '../components/forms/fields/multiselectList';

import regions from '../regions';

import ReactPaginate from 'react-paginate';

class CategoryPage extends Component {
    constructor(props) {
        super(props);
        this.getSearchParams = this.getSearchParams.bind(this);
        this.generateSearchLink = this.generateSearchLink.bind(this);

        this.state = {
            ...props.initialData,
            categories: [],
            testPageNumber: 0 //for pagination on browser back button, used in forcePage pagination attribute
        };
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        let searchParams = this.getSearchParams();
        if (searchParams.search) {
            this.setState({
                search: searchParams.search
            })
        }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname, this.getSearchParams()).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }
    }

    componentDidUpdate(prevProps) {

        console.log(this.props[0].location.search)

        if (prevProps[0].location.pathname != this.props[0].location.pathname || prevProps[0].location.search != this.props[0].location.search) {
            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname, this.getSearchParams()).then((data) => {
                    this.setState({
                        ...data,
                        testPageNumber: this.getSearchParams().page? Number(this.getSearchParams().page) : 0 
                        // if there is no params, then return 0 because it is a first page
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state));
                    })
                })
            }

        }
    }

    getSearchParams() {
        let brokenParams = this.props[0].location.search.replace('?', '').split('&');
        let params = {};
        for (let i = 0; i < brokenParams.length; i++) {
            params[brokenParams[i].split('=')[0]] = decodeURIComponent(brokenParams[i].split('=')[1]);
        }

        if (params.tags) {
            params.tags = params.tags.split(',');
        }

        if (params['compatible-with']) {
            params['compatible-with'] = params['compatible-with'].split(',');
        }

        return params;
    }

    generateSearchLink(name, value, isValueArray) {
        let params = this.getSearchParams();

        if (!value) {
            delete params[name];
        } else {
            if (isValueArray) {
                if (!params[name]) {
                    params[name] = [];
                }


                if (params[name].indexOf(value) !== -1) {
                    params[name].splice(params[name].indexOf(value), 1);
                } else {
                    params[name].push(value);
                }
                params[name] = params[name].join(',');
            } else {
                params[name] = value;
            }
        }

        if (name == 'region') {
            delete params['city'];
        }


        let paramsGroup = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                if (key && params[key])
                    paramsGroup.push(`${key}=${params[key]}`)
            }
        }


        return `?${paramsGroup.join('&')}`;
    }

    generateSortLink(name, value) {
        let params = this.getSearchParams();

        params['sort'] = name;
        params['sortOrder'] = value;

        let paramsGroup = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                if (key && params[key])
                    paramsGroup.push(`${key}=${params[key]}`)
            }
        }


        return `?${paramsGroup.join('&')}`;
    }


    _renderTree = (items) => {
        let breadcrumb = this.props[0].location.pathname.split('/kategorije').pop();

        return items.map((item, idx) => {
            return (
                <li key={idx}>
                    <Link className={this.props[0].location.pathname == `/kategorije${item.breadcrumb}` ?  'active' : ''} to={`/kategorije${item.breadcrumb}`}><div className="head"><span>{item.name}</span></div></Link>

                    {
                        item.subCategories && item.subCategories.length && breadcrumb.indexOf(item.breadcrumb) !== -1 ?
                            <ul>
                                {this._renderTree(item.subCategories)}
                            </ul>
                            :

                            null
                    }


                </li>
            )
        }
        )
    }

    render() {
        let params = this.getSearchParams();
        console.log(params);


        let cities;

        if (params.region) {
            cities = [];
            for (let i = 0; i < regions.length; i++) {
                if (regions[i].name == params.region) {
                    cities = regions[i].cities;
                    break;
                }
            }
        }



        return (
            <div className="category-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>{this.state.category && this.state.category.breadcrumb ? this.state.category.name : 'Kategorije'}</h1>
                                <h2>Dostavljamo brzo!</h2>
                                <button className="scroll-down">
                                    <Isvg src={downArrow} />
                                </button>
                            </Col>
                            <Col lg={{ size: 6 }}>
                                <h5>Pretražite u kategoriji</h5>
                                <div className="search-wrap">
                                    <input type="text" placeholder="Unesite pojam za pretragu" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} onKeyUp={(e) => {
                                        if (e.keyCode == 13) {
                                            e.preventDefault();
                                            this.props[0].history.push(this.props[0].location.pathname + this.generateSearchLink('search', encodeURIComponent(this.state.search)));
                                        }
                                    }} />
                                    <button className="button" onClick={() => {
                                        this.props[0].history.push(this.props[0].location.pathname + this.generateSearchLink('search', encodeURIComponent(this.state.search)));

                                    }}>TRAŽI <Isvg src={rightArrow} /> </button>
                                </div>
                                <div className="count">
                                    <span>{this.state.category && this.state.category.total}</span>  rezultata u {this.state.category && this.state.category.breadcrumb ? this.state.category.name : 'Kategorije'}
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>


                <section className="category-section">
                    <Container>
                        <Row>
                            <Col lg="2" className={this.state.showFilters ? "filters filters-modal" : "filters"}>

                                <div className="filters-top">
                                    <h3>Filteri</h3>
                                    <button className="hide-filters-button" onClick={() => this.setState({ showFilters: null })}>Primjeni</button>

                                </div>

                                <Link to='/kategorije'><h5>Kategorije</h5></Link>


                                {this.state.categories.length ?
                                    <ul className="categories-tree">
                                        {this._renderTree(this.state.categories)}
                                    </ul>

                                    :
                                    null
                                }
                                <div className="spacer"></div>

                                <SelectList label="Regija" value={params.region ? params.region : null} onChange={(val) => {
                                    this.props[0].history.push(this.props[0].location.pathname + this.generateSearchLink('region', val))
                                }}>
                                    {
                                        [{ name: null }].concat(regions).map((item, idx) => {
                                            return (
                                                <option value={item.name}>{item.name ? item.name : 'Sve lokacije'}</option>

                                            )
                                        })
                                    }
                                </SelectList>

                                {
                                    params.region ?
                                        <MultiselectList label="Grad" value={params.city ? params.city.split(',') : []} onChange={(val) => {
                                            this.props[0].history.push(this.props[0].location.pathname + this.generateSearchLink('city', val.join(',')))
                                        }}>
                                            {
                                                cities.map((item, idx) => {
                                                    return (
                                                        <option value={item}>{item}</option>

                                                    )
                                                })
                                            }
                                        </MultiselectList>

                                        :

                                        null
                                }


                                <div className="spacer"></div>
                                <h5>Cijena</h5>
                                <div className="price-wrap">
                                    <div>
                                        <label>Od</label>
                                        <input type="text" value={params.minPrice ? params.minPrice : ''} onChange={(e) => this.props[0].history.push(this.props[0].location.pathname + this.generateSearchLink('minPrice', e.target.value))}></input>
                                    </div>
                                    <div>
                                        <label>Do</label>
                                        <input type="text" value={params.maxPrice ? params.maxPrice : ''} onChange={(e) => this.props[0].history.push(this.props[0].location.pathname + this.generateSearchLink('maxPrice', e.target.value))}></input>
                                    </div>

                                </div>
                                <div className="spacer"></div>

                                <h5>Stanje</h5>
                                <ul className="condition">
                                    <li className={!params.condition ? "active" : null}><Link to={this.props[0].location.pathname + this.generateSearchLink('condition', null)}><button>Sve</button> </Link></li>
                                    <li className={params.condition === 'new' ? "active" : null}><Link to={this.props[0].location.pathname + this.generateSearchLink('condition', 'new')}><button>Novo</button> </Link></li>
                                    <li className={params.condition === 'used' ? "active" : null}><Link to={this.props[0].location.pathname + this.generateSearchLink('condition', 'used')}><button>Korišteno</button> </Link></li>

                                </ul>




                                {this.state.category && this.state.category.ratingCount ?
                                    <>

                                        <div className="spacer"></div>
                                        <h6>Ocjene</h6>
                                        <ul className="options">
                                            <li> <Link to={this.props[0].location.pathname + this.generateSearchLink('rating', null)}> <div className={!params.rating ? 'check checked' : 'check'}> </div> <label>Prikaži sve <span>{this.state.category.count}</span> </label> </Link> </li>
                                            <li> <Link to={this.props[0].location.pathname + this.generateSearchLink('rating', '1-star-and-higher')}> <div className={params.rating == '1-star-and-higher' ? 'check checked' : 'check'}> </div> <label>1  zvjezdica i više <span>{this.state.category.ratingCount['1-star-and-higher']}</span> </label> </Link> </li>
                                            <li> <Link to={this.props[0].location.pathname + this.generateSearchLink('rating', '2-stars-and-higher')}> <div className={params.rating == '2-stars-and-higher' ? 'check checked' : 'check'}> </div> <label>2  zvjezdice i više <span>{this.state.category.ratingCount['2-stars-and-higher']}</span> </label> </Link> </li>
                                            <li> <Link to={this.props[0].location.pathname + this.generateSearchLink('rating', '3-stars-and-higher')}> <div className={params.rating == '3-stars-and-higher' ? 'check checked' : 'check'}> </div> <label>3  zvjezdice i više <span>{this.state.category.ratingCount['3-stars-and-higher']}</span> </label> </Link> </li>
                                            <li> <Link to={this.props[0].location.pathname + this.generateSearchLink('rating', '4-stars-and-higher')}> <div className={params.rating == '4-stars-and-higher' ? 'check checked' : 'check'}> </div> <label>4  zvjezdice i više <span>{this.state.category.ratingCount['4-stars-and-higher']}</span> </label> </Link> </li>
                                        </ul>
                                    </>
                                    :
                                    null
                                }


                                <div className="spacer"></div>



                            </Col>
                            <Col lg="10" className="area">
                                <Row className="top">
                                    <Col lg="5">
                                        <h2>Najnoviji artikli</h2>
                                    </Col>
                                    <Col lg="7" className="sort">
                                        <span>Sortiranje:</span>
                                        <ul>
                                            <li className={!params.sort || params.sort == 'date' ? "active" : null}><Link to={this.props[0].location.pathname + this.generateSortLink('date', params.sortOrder == -1 ? 1 : -1)}><button className={params.sortOrder == -1 ? 'desc' : 'asc'}>Najnovije</button> </Link></li>
                                            <li className={params.sort === 'price' ? "active" : null}><Link to={this.props[0].location.pathname + this.generateSortLink('price', params.sortOrder == -1 ? 1 : -1)}><button className={params.sortOrder == -1 ? 'desc' : 'asc'}>Cijeni</button> </Link></li>
                                            <li className={params.sort === 'name' ? "active" : null}><Link to={this.props[0].location.pathname + this.generateSortLink('name', params.sortOrder == -1 ? 1 : -1)}><button className={params.sortOrder == -1 ? 'desc' : 'asc'}>Nazivu</button> </Link></li>

                                        </ul>
                                    </Col>
                                </Row>
                                <Row className="category-info">
                                    <Col lg="10">
                                        <div className="count">
                                            <span>{this.state.category && this.state.category.total}</span> artikla
                                        </div>
                                        <ul className="bcrumb">
                                            <li><Link to='/'> Početna   </Link></li>
                                            <li><Link to='/kategorije'> Sve kategorije     </Link></li>
                                            {
                                                this.state.category && this.state.category.breadcrumbItems && this.state.category.breadcrumbItems.map((item, idx) => {
                                                    return (
                                                        <li><Link to={`/kategorije${item.breadcrumb}`}> {item.name} </Link></li>

                                                    )
                                                })
                                            }

                                        </ul>
                                    </Col>
                                    <Col lg="2" className="display-style">
                                        <button onClick={() => this.setState({ displayStyle: 'grid' })} className={this.state.displayStyle != 'list' ? 'active' : ''}><Isvg src={grid} /></button>
                                        <button onClick={() => this.setState({ displayStyle: 'list' })} className={this.state.displayStyle == 'list' ? 'active' : ''}><Isvg src={list} /></button>

                                    </Col>
                                </Row>
                                <Row className="mobile-top">
                                    <Col xs="12">
                                        <button className="filters-button" onClick={() => this.setState({
                                            showFilters: true
                                        })}>
                                            Filteri <Isvg src={filterIcon} />
                                        </button>
                                        <div className="sort-wrap">
                                            <span>Sort:</span>
                                            <UncontrolledDropdown>
                                                <DropdownToggle caret>
                                                    Newest
                                        </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem><Link to={this.props[0].location.pathname + this.generateSearchLink('sort', null)}>Newest</Link></DropdownItem>
                                                    <DropdownItem><Link to={this.props[0].location.pathname + this.generateSearchLink('sort', 'best-sellers')}>Best sellers</Link></DropdownItem>
                                                    <DropdownItem><Link to={this.props[0].location.pathname + this.generateSearchLink('sort', 'best-rated')}>Best rated</Link></DropdownItem>
                                                    <DropdownItem><Link to={this.props[0].location.pathname + this.generateSearchLink('sort', 'trending')}>Trending</Link></DropdownItem>

                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                        </div>
                                    </Col>
                                </Row>

                                <Row className="articles">
                                    {
                                        this.state.category && this.state.category.products.map((item, idx) => {
                                            return (
                                                <Col className={this.state.displayStyle == 'list' ? '' : 'article-col'} lg={this.state.displayStyle == 'list' ? 12 : 4} md={this.state.displayStyle == 'list' ? 12 : 6} >
                                                    <Article
                                                        {...item}
                                                        listView={this.state.displayStyle == 'list'}
                                                        addToCart={this.props.addToCart}
                                                        maxNameLength={this.state.displayStyle == 'list' ? 200 : 30}
                                                    />
                                                </Col>

                                            )
                                        })
                                    }


                                </Row>
                                <Row>
                        <Col lg="12">

                            <ReactPaginate
                                forcePage={this.state.testPageNumber} //force on page numer from parameters, solves problem with browser back button and pagnation
                                previousLabel={''}
                                nextLabel={''}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.category && this.state.category.totalPages ? Math.ceil(this.state.category.totalPages) : 1}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={(page) => { {
                                    this.props[0].history.push(this.generateSearchLink('page', page.selected))
                                    window.scrollTo({top: 0, behavior: 'smooth'})
                                
                                } }}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                hrefBuilder={(page) => { return this.generateSearchLink('page', page) }}
                            />

                        </Col>


                    </Row>


                            </Col>
                        </Row>
                    </Container>



                </section>

            </div>
        );
    }
}

export default Page(CategoryPage);