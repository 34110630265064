import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';

import { GoogleMapScript } from '../../components/googleMapScript';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import rightArrow from '../../assets/svg/right-arrow.svg';
import cartIcon from '../../assets/svg/cart.svg';
import moment from 'moment';
import { Line, Bar } from 'react-chartjs-2';
import Text from '../../components/forms/fields/textIcon';
import {baseUrl} from "../../helpers/constants";

class StoreStatisticsPage extends Component {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);

        let currDate = new Date();

        this.state = {
            ...props.initialData,
            categories: [],
            from: moment.unix(new Date(currDate.getFullYear(), currDate.getMonth(), 1, 0, 0, 0).getTime() / 1000).format('DD.MM.YYYY'),
            to: moment.unix(new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0, 0, 0, 0).getTime() / 1000).format('DD.MM.YYYY')
        };
    }

    init() {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }

    }

    componentDidMount() {

        window.scrollTo(0, 0);
        this.init()


        window.Chart.elements.Rectangle.prototype.draw = function () {

            var ctx = this._chart.ctx;
            var vm = this._view;
            var left, right, top, bottom, signX, signY, borderSkipped, radius;
            var borderWidth = vm.borderWidth;
            // Set Radius Here
            // If radius is large enough to cause drawing errors a max radius is imposed
            var cornerRadius = 15;

            if (!vm.horizontal) {
                // bar
                left = vm.x - vm.width / 2;
                right = vm.x + vm.width / 2;
                top = vm.y;
                bottom = vm.base;
                signX = 1;
                signY = bottom > top ? 1 : -1;
                borderSkipped = vm.borderSkipped || 'bottom';
            } else {
                // horizontal bar
                left = vm.base;
                right = vm.x;
                top = vm.y - vm.height / 2;
                bottom = vm.y + vm.height / 2;
                signX = right > left ? 1 : -1;
                signY = 1;
                borderSkipped = vm.borderSkipped || 'left';
            }

            // Canvas doesn't allow us to stroke inside the width so we can
            // adjust the sizes to fit if we're setting a stroke on the line
            if (borderWidth) {
                // borderWidth shold be less than bar width and bar height.
                var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
                borderWidth = borderWidth > barSize ? barSize : borderWidth;
                var halfStroke = borderWidth / 2;
                // Adjust borderWidth when bar top position is near vm.base(zero).
                var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
                var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
                var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
                var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
                // not become a vertical line?
                if (borderLeft !== borderRight) {
                    top = borderTop;
                    bottom = borderBottom;
                }
                // not become a horizontal line?
                if (borderTop !== borderBottom) {
                    left = borderLeft;
                    right = borderRight;
                }
            }

            ctx.beginPath();
            ctx.fillStyle = '#1F1F1F';
            ctx.strokeStyle = 'white';
            ctx.lineWidth = borderWidth;

            // Corner points, from bottom-left to bottom-right clockwise
            // | 1 2 |
            // | 0 3 |
            var corners = [
                [left, bottom],
                [left, top],
                [right, top],
                [right, bottom]
            ];

            // Find first (starting) corner with fallback to 'bottom'
            var borders = ['bottom', 'left', 'top', 'right'];
            var startCorner = borders.indexOf(borderSkipped, 0);
            if (startCorner === -1) {
                startCorner = 0;
            }

            function cornerAt(index) {
                return corners[(startCorner + index) % 4];
            }

            // Draw rectangle from 'startCorner'
            var corner = cornerAt(0);
            ctx.moveTo(corner[0], corner[1]);

            let nextCornerId = 0;
            let nextCorner;


            for (var i = 1; i < 4; i++) {
                corner = cornerAt(i);
                nextCornerId = i + 1;
                if (nextCornerId == 4) {
                    nextCornerId = 0
                }

                nextCorner = cornerAt(nextCornerId);

                let width = corners[2][0] - corners[1][0];
                let height = corners[0][1] - corners[1][1];
                let x = corners[1][0];
                let y = corners[1][1];

                var radius = cornerRadius;

                // Fix radius being too large
                if (radius > height / 2) {
                    radius = height / 2;
                } if (radius > width / 2) {
                    radius = width / 2;
                }

                ctx.moveTo(x + radius, y);
                ctx.lineTo(x + width - radius, y);
                ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                ctx.lineTo(x + width, y + height - radius);
                ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
                ctx.lineTo(x + radius, y + height);
                ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
                ctx.lineTo(x, y + radius);
                ctx.quadraticCurveTo(x, y, x + radius, y);




            }

            ctx.fill();



            ctx.beginPath();
            ctx.fillStyle = vm.backgroundColor;
            ctx.lineWidth = borderWidth;

            // Corner points, from bottom-left to bottom-right clockwise
            // | 1 2 |
            // | 0 3 |
            var corners = [
                [left, bottom],
                [left, top],
                [right, top],
                [right, bottom]
            ];

            // Find first (starting) corner with fallback to 'bottom'
            var borders = ['bottom', 'left', 'top', 'right'];
            var startCorner = borders.indexOf(borderSkipped, 0);
            if (startCorner === -1) {
                startCorner = 0;
            }

            // Draw rectangle from 'startCorner'
            var corner = cornerAt(0);
            ctx.moveTo(corner[0], corner[1]);

            nextCornerId = 0;
            nextCorner;


            for (var i = 1; i < 4; i++) {
                corner = cornerAt(i);
                nextCornerId = i + 1;
                if (nextCornerId == 4) {
                    nextCornerId = 0
                }

                nextCorner = cornerAt(nextCornerId);

                let width = corners[2][0] - corners[1][0];
                let height = corners[0][1] - corners[1][1];
                let x = corners[1][0];
                let y = corners[1][1] + 10;

                var radius = cornerRadius;

                // Fix radius being too large
                if (radius > height / 2) {
                    radius = height / 2;
                } if (radius > width / 2) {
                    radius = width / 2;
                }

                ctx.moveTo(x + radius, y);
                ctx.lineTo(x + width - radius, y);
                ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                ctx.lineTo(x + width, y + height - radius);
                ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
                ctx.lineTo(x + radius, y + height);
                ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
                ctx.lineTo(x, y + radius);
                ctx.quadraticCurveTo(x, y, x + radius, y);




            }

            ctx.fill();

        };


    }

    componentDidUpdate(prevProps) {

        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.init();
        }
    }

    getStatistics = () => {
        let authToken = null;
        if (typeof localStorage !== 'undefined') {
            authToken = 'Bearer ' + localStorage.getItem('authToken');
        }

        fetch(`${baseUrl}/statistics`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': authToken

            },
            body: JSON.stringify({
                from: this.state.from,
                to: this.state.to
            })
        }).then(res => res.json()).then((result) => {
            if (!result.error)
                this.setState({
                    statisticsData: result
                })
        })
    }
    render() {
        let store = this.state.storeData ? this.state.storeData : {};
        console.log(this.state)


        return (
            <div className="store-wrap">

                <div className="top-into-wrap">
                    <Container>
                        <Row>
                            <Col lg="6" sm="6">
                            </Col>
                            <Col lg={{ size: 6 }} sm="6">
                                <div className="search-wrap">
                                    <input type="text" placeholder="Unesite pojam za pretragu" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} onKeyUp={(e) => {
                                        if (e.keyCode == 13) {
                                            e.preventDefault();
                                            this.props[0].history.push(this.props[0].location.pathname + this.generateSearchLink('search', encodeURIComponent(this.state.search)));
                                        }
                                    }} />
                                    <button className="button" onClick={() => {
                                        this.props[0].history.push(this.props[0].location.pathname + this.generateSearchLink('search', encodeURIComponent(this.state.search)));

                                    }}>TRAŽI <Isvg src={rightArrow} /> </button>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>

                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="profile">
                                    <ul className="tabs">
                                        <li >
                                            <Link to='/account/categories'>Kategorije</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/users'>Korisnici</Link>
                                        </li>
                                        <li >
                                            <Link to='/account/stores'>Radnje</Link>
                                        </li>

                                        <li >
                                            <Link to='/account/news'>Novosti</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/pages'>Stranice</Link>
                                        </li>

                                        <li className="active">
                                            <Link to='/account/statistics'>Statistike</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/create-store'>OTVORITE SHOP</Link>
                                        </li>

                                    </ul>

                                    <div className="form-wrapper statistics-wrapper">
                                        <div className="top">
                                            <h3>Statistike</h3>

                                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                                <Text label="Od" value={this.state.from} onChange={(e) => this.setState({from: e.target.value})}></Text>
                                                <div style={{ width: 10 }}></div>
                                                <Text label="Do" value={this.state.to} onChange={(e) => this.setState({to: e.target.value})}></Text>
                                                <button type="button" className="button" style={{ marginLeft: 10 }} onClick={() => {
                                                    this.getStatistics()
                                                }}>PRIKAZI</button>
                                            </div>
                                        </div>
                                        <Container>
                                            <Row>
                                                <Col lg="4">
                                                    <div className="statistics-box">
                                                        <div className="head">
                                                            <h6>Narudžbe</h6>
                                                            <p>Ukupno do sada</p>
                                                        </div>
                                                        <div className="data">
                                                            <h6>{this.state.statisticsData && this.state.statisticsData.ordersCount}</h6>
                                                            <div><Isvg src={cartIcon} /></div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg="4">
                                                    <div className="statistics-box">
                                                        <div className="head">
                                                            <h6>Artikli</h6>
                                                            <p>Ukupno za prodaju</p>
                                                        </div>
                                                        <div className="data">
                                                            <h6>{this.state.statisticsData && this.state.statisticsData.productsCount}</h6>
                                                            <div><Isvg src={cartIcon} /></div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg="4">
                                                    <div className="statistics-box">
                                                        <div className="head">
                                                            <h6>Pregledi artikala</h6>
                                                            <p>Ukupno do sada</p>
                                                        </div>
                                                        <div className="data">
                                                            <h6>{this.state.statisticsData && this.state.statisticsData.productsVisitCount}</h6>
                                                            <div><Isvg src={cartIcon} /></div>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col lg="6">
                                                    <div className="latest-views">
                                                        <h6>NAJPRODAVANIJE</h6>
                                                        {
                                                            this.state.statisticsData && this.state.statisticsData.products.map((item, idx) => {
                                                                return (
                                                                    <div className="item" key={idx}>
                                                                        <img src={item.images ? item.images[0] : null} />
                                                                        <p> x{item._count}  - {item.name}</p>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="latest-views">
                                                        <h6>KATEGORIJE</h6>
                                                        {
                                                            this.state.statisticsData && this.state.statisticsData.categories.map((item, idx) => {
                                                                return (
                                                                    <div className="item" key={idx} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <p> {item.name}  </p>
                                                                        <p> {item.count}</p>

                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Container>


                                    </div>
                                </div>
                            </Col>

                        </Row>

                    </Container>

                </div>


            </div>
        );
    }
}

export default Page(StoreStatisticsPage);
